//Use camelCase to write keys: checkmarkSection, invalidEmail
//For numbers use underscore: balloniIcon_1, alloniIcon_2
export const translations = {
  de: {
    'alerts.ending_subscription.title': 'Dein Balloon Abo',
    'alerts.ending_subscription.web_url_title': 'Abo verlängern',
    'alerts.ending_subscription.message':
      'Dein Balloon-Abonnement wurde gekündigt. Es endet am {subscription_ends_at} - danach wirst du keinen Zugriff mehr auf deine Meditationen haben.',
    'alerts.ending_subscription.web_message':
      'Dein Balloon-Abonnement wurde gekündigt. Es endet am {subscription_ends_at} - danach wirst du keinen Zugriff mehr auf deine Meditationen haben.',
    'alerts.impossible_payment.title': 'Zahlung nicht möglich',
    'alerts.impossible_payment.web_url_title': 'Zahlungsdaten aktualisieren',
    'alerts.impossible_payment.message':
      'Es gibt ein Problem mit deiner Zahlung. Bitte überprüfe deine Zahlungseinstellungen bei {provider} und passe wenn nötig deine Zahlungsmethode an, damit du dein Balloon-Abo weiterhin nutzen kannst.',
    'alerts.impossible_payment.web_message':
      'Es gibt ein Problem mit deiner Zahlung. Bitte überprüfe deine Zahlungseinstellungen und passe wenn nötig deine Zahlungsmethode an, damit du dein Balloon-Abo weiterhin nutzen kannst.',
    'alerts.custom_alert.title': 'Hinweis vom Kundenservice',
    'alerts.custom_alert.message': '',
    'alerts.invalid_email.title': 'Fehlerhafte E-Mail-Adresse',
    'alerts.invalid_email.web_url_title': 'Fehlerhafte E-Mail-Adresse',
    'alerts.invalid_email.message':
      'Deine E-Mail Adresse {email} scheint fehlerhaft zu sein. Möglicherweise kannst du daher keine E-Mails von Balloon empfangen und dich auch nicht mehr in deinem Balloon-Konto anmelden. Bitte überprüfe jetzt in deinem Profil deine E-Mail Adresse und ändere diese, falls sie fehlerhaft ist.',
    'alerts.invalid_email.web_message':
      'Deine E-Mail Adresse {email} scheint fehlerhaft zu sein. Möglicherweise kannst du daher keine E-Mails von Balloon empfangen und dich auch nicht mehr in deinem Balloon-Konto anmelden. Bitte überprüfe jetzt in deinem Profil deine E-Mail Adresse und ändere diese, falls sie fehlerhaft ist.',
    'date.formats.default': '%d.%m.%Y',
    'time.formats.default': '%d.%m.%Y um %H:%Mh',
    'frontpage.testimonials.heading': 'Balloon ist bekannt aus:',
    'frontpage.cta.body':
      "Hol' dir Balloon im Abo. Du hast sofort Zugriff auf unsere komplette Meditations-Bibliothek mit Kursen und Einzel-Übungen zu Themen wie weniger Stress, besserer Schlaf und mehr Gelassenheit. Lass Balloon dein Leben leichter machen.",
    'meta.description':
      "Du willst besser schlafen? Weniger gestresst sein? Meditieren mit Balloon macht dein Leben leichter. Probier's aus: Meditation per App.",
    'meta.keywords': 'Balloon,Meditation,einfach,Leben,Leichtigkeit,Achtsamkeit',
    'meta.title': 'Meditation per App | Balloon',
    //////HomePage Rebrush
    //Video
    'home.videoSection.heading': 'Lass uns noch heute loslegen!',
    'home.videoSection.body':
      'Tauche ein in die Welt von Balloon: Du erfährst, wie Meditation dein Leben leichter macht und bekommst wirksame Tipps für deine Praxis.',
    //Podcast
    'home.podcastSection.heading': 'Der Balloon Podcast',
    'home.podcastSection.body':
      'Ein achtsamer Perspektivwechsel: Glück, Angst, Liebe und Vertrauen – in diesem Podcast gehen wir den ganz großen Dingen im Kleinen nach.',
    //CtaInfo
    'home.ctaInfoSection.heading': 'Hol dir die Balloon App',
    'home.ctaInfoSection.body':
      'Jetzt App installieren und sofort loslegen: In unserem gratis Einstiegskurs lernst du in nur 7 Tagen, wie Meditieren dir helfen kann, gelassener zu werden, weniger gestresst zu sein und besser zu schlafen. Probier es aus!',
    //BenefitInfo
    'home.benefitInfoSection.heading': 'Vielfältig meditieren mit Balloon',
    'home.benefitInfoSection.body':
      'Stress reduzieren, Klarheit finden und endlich besser schlafen – mit deinem Balloon Abo erreichst du deine Ziele. Entdecke passende Einzelübungen und wirksame Kurse in unserer Meditations-Bibliothek.',
    //Boris
    'home.borisSection.heading': 'Kopf und Stimme hinter Balloon',
    'home.borisSection.body':
      'Boris beschäftigt sich seit seinem Psychologie-Studium intensiv mit Meditation. Nach dem Studium hat er das ReSource-Projekt, die weltweit größte Studie zu Meditation, am Max-Planck-Institut mitkonzipiert. Er hat über Meditation promoviert und interessiert sich jetzt vor allem dafür, wie sie sich möglichst einfach und effektiv vermitteln lässt.',
    'home.borisSection.blockquote': '„Im Hier und Jetzt sein mit all deiner Lebendigkeit. Das ist Meditation.“',
    'home.borisSection.figcaption': 'Dr. Boris Bornemann, Diplom-Psychologe, Neurowissenschaftler',
    //Advantages
    'home.advantagesSection.heading': 'Achtsamkeit im App-Format',
    'home.advantagesSection.body': 'Endlich loslegen mit Meditieren – so funktioniert’s:',
    //AdvantageItem1
    'home.advantageItem1.heading': 'Achtsamkeit wirkt',
    'home.advantageItem1.body':
      'Weniger Stress, mehr Gelassenheit, besserer Schlaf – lass dich auf deinem Weg begleiten und finde Ruhe und Entspannung.',
    //AdvantageItem2
    'home.advantageItem2.heading': 'Wissenschaftlich fundiert',
    'home.advantageItem2.body':
      'Balloon basiert auf neuesten Erkenntnissen der Psychologie und Neurowissenschaft. Die Wirksamkeit des Präventionskurses wurde in einer groß angelegten Studie bestätigt.',
    //AdvantageItem3
    'home.advantageItem3.heading': 'für deinen Alltag',
    'home.advantageItem3.body': 'Betrachte Themen, die dich in deinem Leben wirklich bewegen – in wenigen Minuten auf den Punkt gebracht.',
    //testimonialFlow
    'home.testimonialFlow.body':
      'In der App erscheinen regelmäßig neue Inhalte, sodass eine umfassende Achtsamkeits-Bibliothek auf dem Smartphone entsteht - für den Weg ins Büro, die Mittagspause oder ruhige Momente auf dem Sofa.',
    //testimonialBrigitte
    'home.testimonialBrigitte.body':
      'Entspannen per App. Alles was man machen muss: Ruhig hinsetzen, Kopfhörer aufsetzen und der freundlichen Stimme lauschen, die alle weiteren Anweisungen (auf deutsch) durchgibt.',
    //testimonialStern
    'home.testimonialStern.body':
      'Man kann Zen-Buddhismus studieren, um meditieren zu lernen oder sich die App "Balloon" aufs iPhone laden. Anklicken und Augen schließen.'
  },
  en: {
    'alerts.ending_subscription.title': 'Dein Balloon Abo',
    'alerts.ending_subscription.web_url_title': 'Abo verlängern',
    'alerts.ending_subscription.message':
      'Dein Balloon-Abonnement wurde gekündigt. Es endet am {subscription_ends_at} - danach wirst du keinen Zugriff mehr auf deine Meditationen haben.',
    'alerts.ending_subscription.web_message':
      'Dein Balloon-Abonnement wurde gekündigt. Es endet am {subscription_ends_at} - danach wirst du keinen Zugriff mehr auf deine Meditationen haben.',
    'alerts.impossible_payment.title': 'Zahlung nicht möglich',
    'alerts.impossible_payment.web_url_title': 'Zahlungsdaten aktualisieren',
    'alerts.impossible_payment.message':
      'Es gibt ein Problem mit deiner Zahlung. Bitte überprüfe deine Zahlungseinstellungen bei {provider} und passe wenn nötig deine Zahlungsmethode an, damit du dein Balloon-Abo weiterhin nutzen kannst.',
    'alerts.impossible_payment.web_message':
      'Es gibt ein Problem mit deiner Zahlung. Bitte überprüfe deine Zahlungseinstellungen und passe wenn nötig deine Zahlungsmethode an, damit du dein Balloon-Abo weiterhin nutzen kannst.',
    'alerts.custom_alert.title': 'Hinweis vom Kundenservice',
    'alerts.custom_alert.message': '',
    'alerts.invalid_email.title': 'Fehlerhafte E-Mail-Adresse',
    'alerts.invalid_email.web_url_title': 'Fehlerhafte E-Mail-Adresse',
    'alerts.invalid_email.message':
      'Deine E-Mail Adresse {email} scheint fehlerhaft zu sein. Möglicherweise kannst du daher keine E-Mails von Balloon empfangen und dich auch nicht mehr in deinem Balloon-Konto anmelden. Bitte überprüfe jetzt in deinem Profil deine E-Mail Adresse und ändere diese, falls sie fehlerhaft ist.',
    'alerts.invalid_email.web_message':
      'Deine E-Mail Adresse {email} scheint fehlerhaft zu sein. Möglicherweise kannst du daher keine E-Mails von Balloon empfangen und dich auch nicht mehr in deinem Balloon-Konto anmelden. Bitte überprüfe jetzt in deinem Profil deine E-Mail Adresse und ändere diese, falls sie fehlerhaft ist.',
    'date.formats.default': '%d.%m.%Y',
    'time.formats.default': '%d.%m.%Y um %H:%Mh',
    'frontpage.advantages.heading': 'Meditation per App\u003cbr\u003e- so funktioniert Balloon:',
    'frontpage.testimonials.heading': 'Balloon ist bekannt aus:',
    'frontpage.cta.body':
      "Hol' dir Balloon im Abo. Du hast sofort Zugriff auf unsere komplette Meditations-Bibliothek mit Kursen und Einzel-Übungen zu Themen wie weniger Stress, besserer Schlaf und mehr Gelassenheit. Lass Balloon dein Leben leichter machen.",
    'meta.description':
      "Du willst besser schlafen? Weniger gestresst sein? Meditieren mit Balloon macht dein Leben leichter. Probier's aus: Meditation per App.",
    'meta.keywords': 'Balloon,Meditation,einfach,Leben,Leichtigkeit,Achtsamkeit',
    'meta.title': 'Meditation per App | Balloon',
    //////HomePage Rebrush
    //Video
    'home.videoSection.heading': 'Lass uns noch heute loslegen!',
    'home.videoSection.body':
      'Tauche ein in die Welt von Balloon: Du erfährst, wie Meditation dein Leben leichter macht und bekommst wirksame Tipps für deine Praxis.',
    //Podcast
    'home.podcastSection.heading': 'Der Balloon Podcast',
    'home.podcastSection.body':
      'Ein achtsamer Perspektivwechsel: Glück, Angst, Liebe und Vertrauen – in diesem Podcast gehen wir den ganz großen Dingen im Kleinen nach.',
    //CtaInfo
    'home.ctaInfoSection.heading': 'Hol dir die Balloon App',
    'home.ctaInfoSection.body':
      'Jetzt App installieren und sofort loslegen: In unserem gratis Einstiegskurs lernst du in nur 7 Tagen, wie Meditieren dir helfen kann, gelassener zu werden, weniger gestresst zu sein und besser zu schlafen. Probier es aus!',
    //BenefitInfo
    'home.benefitInfoSection.heading': 'Vielfältig meditieren mit Balloon',
    'home.benefitInfoSection.body':
      'Stress reduzieren, Klarheit finden und endlich besser schlafen – mit deinem Balloon Abo erreichst du deine Ziele. Entdecke passende Einzelübungen und wirksame Kurse in unserer Meditations-Bibliothek.',
    //Boris
    'home.borisSection.heading': 'Kopf und Stimme hinter Balloon',
    'home.borisSection.body':
      'Boris beschäftigt sich seit seinem Psychologie-Studium intensiv mit Meditation. Nach dem Studium hat er das ReSource-Projekt, die weltweit größte Studie zu Meditation, am Max-Planck-Institut mitkonzipiert. Er hat über Meditation promoviert und interessiert sich jetzt vor allem dafür, wie sie sich möglichst einfach und effektiv vermitteln lässt.',
    'home.borisSection.blockquote': '„Im Hier und Jetzt sein mit all deiner Lebendigkeit. Das ist Meditation.“',
    'home.borisSection.figcaption': 'Dr. Boris Bornemann, Diplom-Psychologe, Neurowissenschaftler',
    //Advantages
    'home.advantagesSection.heading': 'Achtsamkeit im App-Format',
    'home.advantagesSection.body': 'Endlich loslegen mit Meditieren – so funktioniert’s:',
    //AdvantageItem1
    'home.advantageItem1.heading': 'Achtsamkeit wirkt',
    'home.advantageItem1.body':
      'Weniger Stress, mehr Gelassenheit, besserer Schlaf – lass dich auf deinem Weg begleiten und finde Ruhe und Entspannung.',
    //AdvantageItem2
    'home.advantageItem2.heading': 'mit Wissenschaft',
    'home.advantageItem2.body':
      'Balloon basiert auf neuesten Erkenntnissen der Psychologie und Neurowissenschaft. Die Wirksamkeit des Präventionskurses wurde in einer groß angelegten Studie bestätigt.',
    //AdvantageItem3
    'home.advantageItem3.heading': 'für deinen Alltag',
    'home.advantageItem3.body': 'Betrachte Themen, die dich in deinem Leben wirklich bewegen – in wenigen Minuten auf den Punkt gebracht.',
    //testimonialFlow
    'home.testimonialFlow.body':
      'In der App erscheinen regelmäßig neue Inhalte, sodass eine umfassende Achtsamkeits-Bibliothek auf dem Smartphone entsteht - für den Weg ins Büro, die Mittagspause oder ruhige Momente auf dem Sofa.',
    //testimonialBrigitte
    'home.testimonialBrigitte.body':
      'Entspannen per App. Alles was man machen muss: Ruhig hinsetzen, Kopfhörer aufsetzen und der freundlichen Stimme lauschen, die alle weiteren Anweisungen (auf deutsch) durchgibt.',
    //testimonialStern
    'home.testimonialStern.body':
      'Man kann Zen-Buddhismus studieren, um meditieren zu lernen oder sich die App "Balloon" aufs iPhone laden. Anklicken und Augen schließen.'
  }
};

export const TEXT = {
  brandName: 'Balloon',
  header: {
    menu: {
      home: 'Home',
      healthInsurance: 'Krankenkassen',
      company: 'Unternehmen',
      library: 'Meditationen',
      plans: 'Preise',
      login: 'Anmelden',
      signup: 'Registrieren'
    }
  },
  checkout: {
    paymentMethodPaypal: 'PayPal',
    paymentMethodCreditCard: 'Kreditkarte',
    paymentMethodDebit: 'Debitkarte',
    paymentMethodSepa: 'Lastschrift',
    paymentMethodNone: 'Benutzerdefinierte Zahlungsmethode',
    headerSubTitleGift:
      'Verschenke jetzt Zugriff auf die komplette Balloon-Bibliothek mit Kursen zu Themen wie Stress, Schlaf, Fokus und Klarheit.',
    headerSubTitle:
      'Erhalte jetzt Zugriff auf die komplette Balloon-Bibliothek mit Kursen zu Themen wie Stress, Schlaf, Fokus und Klarheit.',
    headerSubTitlePraeventionskurs:
      'Erhalte jetzt Zugriff auf den Kurs „Stressreduktion durch Achtsamkeit" sowie auf die komplette Balloon-Bibliothek mit Kursen zu Themen wie Schlaf, Fokus und Klarheit.',
    noAbos: 'Im Moment stehen leider keine Abos zur Auswahl.',
    item_titles: {
      yearly_gift: 'Geschenkgutschein verschenken',
      monthly: 'Krankenkassen Abo kaufen',
      yearly: 'yearly',
      onetime: 'onetime',
      yearly_insurance: 'yearly_insurance',
      onetime_gift: 'onetime_gift'
    },
    item_sub_titles: {
      yearly_gift: 'Geschenkgutschein verschenken',
      monthly: 'Krankenkassen Abo kaufen',
      yearly: 'yearly',
      onetime: 'onetime',
      yearly_insurance: 'yearly_insurance',
      onetime_gift: 'onetime_gift'
    },
    sectionHeadlineMail: 'Deine E-Mail Adresse',
    sectionHeadlineContact: 'Deine Kontaktdaten',
    sectionHeadlineText: 'DEINE BEZAHLMETHODE',
    btnMail: 'weiter zu den Kontaktdaten',
    btnContact: 'weiter zur Bezahlmethode',
    btnPaymentMethod: 'Zur Übersicht',
    btnEdit: 'Ändern',
    subscriptionDisclaimer:
      '*Du kannst das Abo jederzeit zum Ende der Abo-Laufzeit kündigen. Ansonsten verlängert sich das Abo automatisch um die Dauer des jeweiligen Abo-Zeitraums und kann jederzeit mit einer Frist von einem Monat gekündigt werden. Die Bezahlung erfolgt vorab für den jeweiligen Abo-Zeitraum. Sofern das Abo vor Ablauf des jeweiligen Abo-Zeitraums wirksam durch Kündigung beendet wird, wird Dir die Bezahlung anteilig erstattet. Erfordert Android 8.0 bzw. iOS 13.0 oder höher.'
  },
  checkoutConfirm: {
    bubble_1: 'Bei einem Kauf benötigt deine Bank möglicherweise eine\n',
    bubble_2: ' Autorisierung von einem 0,00 EUR-Betrag.\n',
    bubble_3: 'Diese kannst du im nächsten Schritt einfach bestätigen,\num deinen Kauf bei Balloon voll abzuschließen.',
    bubbleAbo_1: 'Bei einem Abo-Kauf benötigt deine Bank möglicherweise eine\n',
    btnText_1: 'Jetzt für ',
    btnText_2: ' kaufen'
  },
  coupon: {
    invalid: 'Dieser Promotion-Code ist leider nicht gültig.',
    expired: 'Der Gültigkeitszeitraum für diesen Promotion-Code ist bereits abgelaufen.',
    maxRedeemed: 'Dieser Promotion-Code wurde bereits zu häufig eingelöst.',
    notApplicable: 'Dieser Promotion-Code gilt für keinen der aktuellen Abo-Pläne.'
  },
  downloadAd: {
    benefit1: 'Kurse und Übungen offline anhören',
    benefit2: 'Mehr entdecken: Podcast, Impulse und Inspirationen',
    benefit3: 'Deine Fortschritte bemerken und dranbleiben',
    cta: 'APP JETZT HERUNTERLADEN',
    decline: 'JETZT NICHT',
    typeCourse: 'Kurs',
    typeSingle: 'Übung'
  },
  thankYou: {
    btnLibrary: 'Zur Balloon-Bibliothek',
    btnLogin: 'Jetzt anmelden',
    btnProfile: 'zurück zum profil',
    copyBottomSection: 'Probiers mal mit Ausloggen und wieder Einloggen in deinen Profil-Einstellungen!',
    copyGiftPurchase: 'Eine E-Mail mit dem Geschenk-Abo ist auf dem Weg zu dir.\n\nViel Spaß beim Verschenken!',
    copyRegularPurchase:
      'Du hast jetzt Zugriff auf die komplette Balloon-Bibliothek mit Kursen zu Themen wie Stress, Schlaf, Fokus und Klarheit',
    copyPurchaseAssociated_1: 'Dein Kauf wurde in deinem Balloon Account',
    copyPurchaseAssociated_2:
      'freigeschaltet - melde dich damit in der App an und du kannst ab sofort die komplette Balloon-Bibliothek mit Kursen zu Themen wie Stress, Schlaf und Fokus nutzen.',
    title: 'Dein Kauf war erfolgreich!',
    titleBottomSection: 'Deine App lädt die Inhalte nicht?'
  },
  thankYouUpgrade: {
    title: 'Dein Upgrade war erfolgreich!'
  },
  upgrade: {
    title: 'Dein Balloon Upgrade',
    subscriptionDisclaimer:
      '* Das Angebot ist ab sofort ab dem Upgradezeitpunkt gültig. Du kannst das Abo jederzeit zum Ende der Abo-Laufzeit kündigen. Ansonsten wird das Abo 24 Stunden vor Ablauf automatisch um den jeweiligen Abo-Zeitraum verlängert. Die Bezahlung erfolgt vorab bzw. zum Zeitpunkt der Verlängerung. Erfordert Android 8.0 bzw. iOS 13.0 oder höher.',
    codeSubscriptionDisclaimer:
      '* Das Angebot ist ab sofort ab dem Upgradezeitpunkt gültig. Der Rabatt gilt für die ersten 12 Monate, danach zum normalen Preis. Du kannst das Abo jederzeit zum Ende der Abo-Laufzeit kündigen. Ansonsten wird das Abo 24 Stunden vor Ablauf automatisch um den jeweiligen Abo-Zeitraum verlängert. Die Bezahlung erfolgt vorab bzw. zum Zeitpunkt der Verlängerung. Erfordert Android 8.0 bzw. iOS 13.0 oder höher.',
    saving: 'Spare ',
    infoText:
      ' im \nJahr und erhalte ab sofort ein Jahr lang Zugriff auf die komplette\n Balloon-Bibliothek mit Kursen zu Themen wie Stress, Schlaf, Fokus\n und Klarheit. Von deinem laufenden Monats-Abo werden dir ',
    credited: ' angerechnet.',
    savingPerMonth: ' Ersparnis pro Monat',
    savingCurrentMonth: ' gegenüber deinem derzeitigen Monatsabo',
    btnTitle_1: 'Jetzt für ',
    btnTitle_2: ' upgraden'
  },
  paymentError: {
    title: 'Huch - das tut uns Leid!',
    copy: 'Da ist irgendwas schief gelaufen mit deiner Zahlung. Bitte beginne von Vorne oder - wenn du unsicher bist, kontaktiere unseren Kunden-Support.',
    btn: 'Zurück zum Abonnement'
  },
  upgradeError: {
    title: 'Huch - das tut uns Leid!',
    copy: 'Da ist irgendwas schief gelaufen mit deinem Upgrade. Bitte beginne von Vorne oder - wenn du unsicher bist, kontaktiere unseren Kunden-Support',
    btn: 'Zurück zum Upgrade'
  },
  thankyouloggedout: {
    title: 'Kauf übertragen',
    text: 'Unter der angegebenen E-Mail Adresse haben wir keinen bestehenden Balloon Account gefunden. Bitte registriere dich jetzt, um deinen Kauf bei Balloon nutzen zu können.',
    login_btn: 'ANMELDEN',
    register_btn: 'REGISTRIEREN',
    terms_1:
      'Als Balloon-Nutzer erhältst du regelmäßig Achtsamkeits-Tipps sowie Informationen über Angebote und Aktionen rund um Balloon per E-Mail. Über einen Link am Ende jeder E-Mail kannst du dem jederzeit widersprechen Es gelten unsere',
    terms_2: 'Alles zum Datenschutz findest du'
  },
  gift: {
    headline: 'Verschenke Leichtigkeit',
    subline: 'Zugang zur gesamten Balloon Bibliothek',
    buyBtn: 'Jetzt verschenken',
    intervalMonthly: '1 Monat - ',
    intervalYearly: '1 Jahr - ',
    intervalQuarterly: '3 Monate -',
    works: {
      headline: 'So funktioniert’s',
      bubble_1: {
        headline: 'Gutschein kaufen',
        subline: 'Gutschein auswählen und mit nur \nwenigen Klicks online kaufen.'
      },
      bubble_2: {
        headline: 'Gutschein erhalten',
        subline: 'Liebevoll gestalteten Gutschein per \n E-Mail erhalten - bequem und schnell zum Selbstausdrucken oder \nWeiterleiten!'
      },
      bubble_3: {
        headline: 'Gutschein verschenken',
        subline: 'Der oder die Beschenkte kann den \n Geschenk-Code jederzeit einlösen und \n direkt mit der ersten \n Meditation starten!'
      }
    }
  },
  giftCoupon: {
    invalid: 'Dieser Gutschein-Code ist leider nicht gültig.',
    expired: 'Der Gültigkeitszeitraum für diesen Gutschein-Code ist bereits abgelaufen.',
    alreadyRedeemed: 'Dieser Gutschein-Code wurde bereits eingelöst.'
  },
  home: {
    title: 'Willkommen'
  },
  profile: {
    heading: 'Persönliche Daten',
    nameLabel: 'Dein Name',
    emailAddressLabel: 'Deine E-Mail Adresse',
    currentMailAddressLabel: 'Aktuelle E-Mail',
    newMailAddressLabel: 'Neue E-Mail',
    unConfirmedMail: 'Noch nicht bestätigt.',
    unConfirmedMailLink: 'Neue E-Mail jetzt bestätigen'
  },
  subscriptionStatus: {
    title: 'Freischaltung',
    heading: 'Deine Freischaltung',
    subscribeButtonText: 'Balloon-Abo abschließen',
    noSubscriptionTeaser:
      'Mit einem Balloon-Abo erhältst du\n' +
      '      Zugriff auf eine wachsende Bibliothek an Audio-Meditationen. Absolviere\n' +
      '      ganze Themenkurse oder steig mit kleinen Übungen ein, um mehr Fokus und\n' +
      '      Klarheit in deinen Alltag zu bringen.',
    noSubscriptionCta: 'Lass Balloon dein Leben leichter machen!',
    redeemGiftCouponHeadline: 'Dein Gutschein-Code',
    redeemGiftCoupon: 'Geschenk-Gutschein einlösen',
    redeemGiftCouponText: 'Du hast einen Gutschein-Code? Hier kannst du ihn einlösen.',
    redeemGiftCouponTextHasAbo:
      'Hier kannst du normalerweise Gutschein-Codes einlösen. Du hast aber bereits ein gültiges Abonnement, daher ist das aktuell nicht möglich. Wenn du Hilfe benötigst wende dich gerne an unseren Kundensupport unter info@balloonapp.de',
    paymentMethodHeadlingOneTimePurchase: 'Zahlungsmethode deines letzten Kaufes',
    paymentMethodEditable: 'Zahlungsmethode ändern',
    paymentMethodNotEditable: 'Zahlungsmethode deiner nächsten Abbuchung',
    billingAddressHeadline: 'Rechnungsadresse deiner nächsten Abbuchung',
    billingAddressHeadlineOneTimePurchase: 'Rechnungsadresse deines letzten Kaufes'
  },
  subscriptionPlans: {
    finalHeading: 'Dein Zugang zur Balloon-Welt',
    reducedHeading: 'Einfach günstiger meditieren',
    loadingHeading: 'Einen Moment…',
    lifetimePlan: {
      buttonText: 'Nur im App Store'
    }
  },
  subscriptionTeaser: {
    title: 'Endlich durchatmen',
    message: 'Mit deinem Zugang zur gesamten Balloon-Bibliothek.'
  },
  personalData: {
    heading: 'Persönliche Daten'
  },
  codeEntry: {
    heading: 'E-Mail bestätigen'
  },
  help: {
    heading: 'Du hast noch Fragen?',
    text: 'Wenn du Fragen zu Balloon oder deinem Abo hast, dann schreibe uns eine\n' + '      Nachricht.',
    buttonText: 'Dem Support eine E-Mail schreiben'
  },
  invoices: {
    title: 'Rechnungen',
    heading: 'Deine Rechnungen'
  },
  invoice: {
    title: 'Rechnung',
    heading: 'Rechnung'
  },
  login: {
    alreadyLoggedIn: 'Du bist bereits eingeloggt',
    ctaAppleLogin: 'Bitte logge dich über Apple ein.',
    cta_check: 'Du hast noch keinen Login?',
    cta_google_login: 'Bitte logge dich über Google ein.',
    cta_register: 'Registriere dich hier!',
    description: 'Noch mehr Balloon kannst du in der App entdecken. Jetzt auf dein Smartphone oder Tablet laden:',
    descriptionImageAlt: 'Entdecke',
    descriptionTitle: 'Willkommen zurück!',
    googleEmailUsedError: 'Mit dieser E-Mail-Adresse bist du per E-Mail-Anmeldung registriert.\nBitte melde dich mit deiner E-Mail an.',
    google_mail_hint: 'Mit dieser E-Mail Adresse bist du bereits über Google registriert.',
    hint: 'Noch kein Balloon-Konto?\n',
    login_btn: 'Anmelden',
    login_google_btn: 'Mit Google anmelden',
    login_with_insurance_number: 'Login Password zuschicken',
    pw_mail_error: 'E-Mail-Adresse oder Passwort ist falsch',
    registrationHint: 'Registriere dich hier.',
    title: 'Mit E-Mail Anmelden',
    voucherHint: 'Bitte melde dich erst an, um deinen Gutschein einzulösen.'
  },
  registration: {
    alreadyRegistered: 'Du bist bereits registriert',
    alreadyTakenAppleError: ' Mit dieser E-Mail Adresse bist du bereits über Apple registriert.',
    alreadyTakenGoogleError: ' Mit dieser E-Mail Adresse bist du bereits über Google registriert.',
    alreadyTakenSocialError: 'Mit dieser E-Mail Adresse bist du per E-Mail Anmeldung registriert.\nBitte melde dich mit deiner E-Mail an.',
    alreadyTokenUsedApple: 'Bitte registriere dich zuerst über deinen Apple-Account in der \niOS-App.',
    alreadyUserHint: ' Du hast bereits ein Balloon-Konto? ',
    descriptionElement1: 'Weniger Stress, mehr Zeit für dich',
    descriptionElement2: 'Mit Gelassenheit durch den Alltag',
    descriptionElement3: 'Innere Ruhe, besserer Schlaf',
    descriptionImageAlt: 'Gelassenheit',
    descriptionTitle: 'Lass Balloon dein Leben leichter machen',
    email_already_exists: 'Diese E-Mail-Adresse kann nicht verwendet werden, bitte überprüfe deine Eingabe.',
    headline_google: 'Mit Google Registrieren',
    hint: 'Du hast bereits einen Balloon-Konto?\n',
    insuranceNumberAlreadyExists: 'Bitte überprüfe deine Versicherungsnummer',
    loginHint: 'Melde dich hier an.',
    newsletterDisclaimer:
      'Als Balloon-Nutzer erhältst du regelmäßig Achtsamkeits-Tipps sowie Informationen über Angebote und Aktionen rund um Balloon per E-Mail. Über einen Link am Ende jeder E-Mail kannst du dem jederzeit widersprechen.',
    registerButton: 'Kostenlos Registrieren',
    register_btn: 'Registrieren',
    subtitle:
      'Mit deiner Registrierung sicherst du dir Zugang zu  Achtsamkeits-Tipps, exklusiven Angeboten und Informationen über neue App-Inhalte. Du kannst der E-Mail-Zusendung jederzeit widersprechen.',
    title: 'Mit E-Mail Registrieren'
  },
  requiredFieldIndicator: '*',
  plans: {
    couponQuestion: 'Du hast einen Promotion-Code?',
    redeem: 'Rabatt-Coupon einlösen',
    redeemShort: 'Einlösen',
    reducedSubheading:
      'Weniger Stress, besserer Schlaf und mehr Gelassenheit gibt es jetzt besonders günstig! Mit diesem Balloon-Angebot sicherst du dir deinen Zugang zur App zum reduzierten Preis.',
    subheading:
      'Weniger Stress, besserer Schlaf, mehr Zeit für das, was dir wichtig ist – die Kurse, Übungen und Inspirationen in der Balloon-Bibliothek bringen dich weiter. '
  },
  stripe_errors: {
    api_connection_error:
      'Wir können aktuell keine Verbindung zu unserem Zahlungsdienstleister aufbauen. Bitte probiere es später noch einmal.',
    api_error: 'Beim Zahlungsdienstleister ist ein Schnittstellenproblem aufgetreten. Bitte probiere es später noch einmal.',
    authentication_error: undefined,
    idempotency_error: undefined,
    invalid_request_error: undefined,
    rate_limit_error: 'Der Zahlungsdienstleister ist gerade überlastet. Bitte probiere es später noch einmal.',
    validation_error: {
      incomplete_number: 'Die Kartennummer ist unvollständig.',
      invalid_number: 'Die Kartennummer ist ungültig.',
      invalid_expiry_year_past: 'Das Ablaufjahr liegt in der Vergangenheit.',
      invalid_expiry_year: 'Das Ablaufjahr ist ungültig.'
    },
    card_error: {
      card_declined: 'Die Karte konnte nicht belastet werden.',
      expired_card: 'Die Karte ist abgelaufen.',
      incorrect_cvc: 'Der Sicherheitscode der Karte ist inkorrekt.',
      incorrect_number: 'Die Kartennummer ist inkorrekt.',
      incorrect_zip: 'Die Postleitzahl ist inkorrekt',
      invalid_cvc: 'Der Sicherheitscode der Karte ist invalide.',
      invalid_expiry_month: 'Der Ablaufmonat ist ungültig.',
      invalid_expiry_year: 'Das Ablaufjahr ist ungültig.',
      invalid_number: 'Die Kartennummer ist ungültig.',
      invalid_swipe_data: undefined,
      missing: undefined,
      processing_error: 'Bei der Kartenverarbeitung ist ein Fehler aufgetreten.'
    }
  },
  subscriptionCard: {
    aboPlan_btn: 'Abo kaufen',
    badgeTitleSlogan: 'Nur für kurze Zeit!',
    badgeTitleZpp: 'Die Krankenkasse zahlt!',
    caption: 'Die Krankenkasse übernimmt 75-100%',
    discount: 'Rabatt',
    google_btn: 'Abonnieren mit',
    Month: 'Monat',
    oneTimePayment_btn: 'Jetzt kaufen',
    preText_discount: 'statt',
    separator: 'oder',
    zpp_content: 'Die Krankenkasse übernimmt 75-100%',
    zpp_headline: 'ERSTATTUNGSFÄHIG'
  },
  courseTeaser: {
    headline: ' Weitere Kurse, die dich interessieren könnten',
    libraryButton: 'Meditations-Übersicht'
  },
  singleTeaser: {
    headline: 'Weitere Übungen, die dich interessieren könnten'
  },
  libraryBadges: {
    new: 'NEU',
    special: 'SPECIAL'
  },
  contentTypes: {
    course: 'Kurs',
    single: 'Übung'
  },
  library: {
    current: 'Aktuell',
    units: 'Einheiten',
    min: 'Min'
  },
  appAdTile: {
    headline: 'Sichere dir jetzt den\n Zugriff auf alle Kurse\n und Übungen ',
    button: 'Jetzt freischalten',
    altText: 'Balloonfigur hört Musik'
  },
  healthCheckoutBox: {
    asteriskText:
      '* Freischaltung endet automatisch nach einem Jahr und wird nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.',
    ctaBtn: 'Jetzt kaufen',
    approvalText_1: 'Ich akzeptiere die',
    approvalText_1a: 'und bestätige hiermit, dass keine Kontraindikationen für die Absolvierung des Kurses vorliegen.',
    approvalText_2: 'Ich akzeptiere die',
    approvalText_3: 'und die Hinweise zum',
    approvalText_4: 'zu',
    interval: 'Einmalig *',
    infoText: 'inkl. 12 Monate Balloon'
  },
  healthCheckout: {
    selected: {
      other: {
        headline: 'Wie schön!'
      },
      general: {
        subline: 'Kein Abo, keine Kündigung notwendig.',
        btnText: 'Krankenkasse ändern',
        refundText: ' der Kosten für dieses Angebot erstattet.',
        time: 'Achte darauf, den Kurs in 6 Monaten abzuschließen.',
        notice: 'Hinweis:',
        member: 'Für Versicherte der ',
        info: 'gibt es außerdem unter ',
        info_2: 'die Möglichkeit, den Präventionskurs direkt zu \nstarten, ohne etwas vorlegen zu müssen.'
      },
      calcBox: {
        refund: 'Erstattung',
        price: 'Preis (Vorleistung)',
        yourShare: 'Dein Anteil'
      },
      dak: {
        headline: 'Fantastisch!',
        info: 'Für DAK Gesundheit Versicherte gibt es ein kostenfreies Angebot. \nDu kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'DAK Antistress-Coaching (achtsamkeitsbasiert)',
          infoText: 'inkl. 3 Monate Balloon',
          asteriskText:
            '* Freischaltung endet automatisch nach einem Jahr und wird nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      viactiv: {
        headline: 'Fantastisch!',
        info: 'Für VIACTIV Versicherte gibt es ein kostenfreies Angebot. \nDu kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Stressreduktion durch Achtsamkeit (VIACTIV)',
          infoText: 'inkl. 6 Monate Balloon',
          asteriskText:
            '* Freischaltung endet automatisch nach einem Jahr und wird nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      mkk: {
        headline: 'Fantastisch!',
        info: 'Für mkk Versicherte gibt es ein kostenfreies Angebot. \nDu kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Mindful Me: Dein Weg\n zur inneren Balance',
          infoText: 'inkl. 6 Monate Balloon',
          asteriskText:
            '* Freischaltung des Kurses endet automatisch nach einem Jahr und wird, ebenso wie der Balloon-Zugang, nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      bigDirect: {
        headline: 'Fantastisch!',
        info: 'Für BIG direkt gesund Versicherte gibt es ein kostenfreies Angebot. Du kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Stressfrei &\nachtsam leben',
          infoText: 'inkl. 12 Monate Balloon',
          asteriskText:
            '* Freischaltung des Kurses endet automatisch nach einem Jahr und wird, ebenso wie der Balloon-Zugang, nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      ikkClassic: {
        headline: 'Fantastisch!',
        info: 'Für IKK classic Versicherte gibt es ein kostenfreies Angebot. Du kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Stressreduktion\ndurch Achtsamkeit',
          infoText: 'inkl. 6 Monate Balloon',
          asteriskText:
            '* Freischaltung des Kurses endet automatisch nach einem Jahr und wird, ebenso wie der Balloon-Zugang, nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      hkk: {
        headline: 'Fantastisch!',
        info: 'Für hkk Versicherte gibt es ein kostenfreies Angebot. Du kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Stressreduktion durch Meditation & Achtsamkeit',
          infoText: 'inkl. 6 Monate Balloon',
          asteriskText:
            '* Freischaltung des Kurses endet automatisch nach einem Jahr und wird, ebenso wie der Balloon-Zugang, nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      vividaBkk: {
        headline: 'Fantastisch!',
        info: 'Für vivida bkk Versicherte gibt es ein kostenfreies Angebot. Du kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Stressreduktion\ndurch Achtsamkeit',
          infoText: 'inkl. 6 Monate Balloon',
          asteriskText:
            '* Freischaltung des Kurses endet automatisch nach einem Jahr und wird, ebenso wie der Balloon-Zugang, nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      salusBkk: {
        headline: 'Fantastisch!',
        info: 'Für Salus BKK Versicherte gibt es ein kostenfreies Angebot. Du kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Stressreduktion\ndurch Achtsamkeit',
          infoText: 'inkl. 6 Monate Balloon',
          asteriskText:
            '* Freischaltung des Kurses endet automatisch nach einem Jahr und wird, ebenso wie der Balloon-Zugang, nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      tk: {
        headline: 'Fantastisch!',
        info: 'Für Versicherte der Techniker Krankenkasse gibt es ein kostenfreies Angebot. Du kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Stressreduktion\ndurch Achtsamkeit',
          infoText: 'inkl. 12 Monate Balloon',
          asteriskText:
            '* Freischaltung des Kurses endet automatisch nach einem Jahr und wird, ebenso wie der Balloon-Zugang, nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      },
      aokBayern: {
        headline: 'Fantastisch!',
        info: 'Für Versicherte der AOK Bayern gibt es ein kostenfreies Angebot. Du kannst ohne Vorauszahlung loslegen.',
        box: {
          aboTitle: 'Stressreduktion\ndurch Achtsamkeit',
          infoText: 'inkl. 6 Monate Balloon',
          asteriskText:
            '* Freischaltung des Kurses endet automatisch nach einem Jahr und wird, ebenso wie der Balloon-Zugang, nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.'
        }
      }
    },
    headline: 'Mach jetzt den Erstattungs-Check:',
    text: 'Sichere dir den Präventionskurs "Stressreduktion durch Achtsamkeit" und 12 Monate Zugang zur gesamten Balloon Bibliothek.',
    subline: 'Wie hoch ist deine Erstattung?'
  },
  healthExpert: {
    headline_1: 'Unser Experte',
    headline_2: 'Dr. Boris Bornemann',
    text: 'Boris ist promovierter Neurowissenschaftler. Er ist Experte für Achtsamkeit und Meditation und Mitverfasser der weltweit größten wissenschaftlichen Studie zu diesem Thema.',
    ctaBtn: 'Jetzt Loslegen'
  },
  healthHeader: {
    headline: 'Stressreduktion durch Achtsamkeit',
    subline_1: 'Ein App-gestützter Online-Kurs',
    subline_2: 'kostenfrei über deine Krankenkasse nutzen',
    ctaBtn: 'Jetzt Loslegen'
  },
  healthInstructions: {
    headline_1: 'So funktioniert es',
    headline_2: 'In 4 Schritten zur Erstattung',
    headlineBold_5: 'Wichtige Hinweise',
    cardBody_5_1:
      'Bewahre nach dem Kauf den Kaufbeleg auf. Diesen benötigst du später bei deiner Krankenkasse für die Erstattung. Einige Kassen beschränken die Anzahl der Erstattungen pro Person auf 1-2 Maßnahmen im Jahr. Nähere Infos dazu erhältst du bei deiner Krankenkasse.',
    cardBody_5_2:
      'Nach 12 Monaten läuft dein Zugang automatisch aus, ohne, dass du ihn aktiv kündigen musst. Du kannst bei Gefallen den Kauf des Kurses erneut abschließen und dir nochmals von deiner Krankenkasse erstatten lassen.',
    ctaBtn: 'Jetzt Loslegen'
  },
  healthIntro: {
    headline: 'Komm zur Ruhe!',
    text_1:
      'Mit dem speziell konzipierten Kurs „Stressreduktion durch Achtsamkeit – Ein App-gestützter Online-Kurs“ lernst du Stress zu reduzieren und gelassener durchs Leben zu gehen.',
    text_2:
      'In leicht zugänglichen und verständlichen Einheiten vermittelt dir unser Experte Dr. Boris Bornemann einen guten Einstieg in die Achtsamkeitspraxis. Er zeigt dir, wie du Stress wirksam vorbeugen und entgegenwirken kannst. Das Programm begleitet dich Schritt für Schritt.',
    text_3:
      'Balloon kooperiert mit gesetzlichen Krankenkassen. Je nach Krankenkasse können bis zu 100% der Kosten für Balloon erstattet werden.'
  },
  healthSurvey: {
    right: {
      title: 'Sehr gut! Das war richtig.',
      text: 'Mach weiter so...'
    },
    wrong: {
      title: 'Schade, das war leider nicht richtig.',
      text: 'Probiere es doch noch einmal!'
    },
    maybe: {
      title: 'Das stimmt noch nicht so ganz.',
      text: 'Schau dir nochmal die gesamte Frage an.'
    }
  },
  kaifuHero: {
    title: 'Erst sportlich auspowern - dann zur Ruhe kommen'
  },
  kaifuHeroCard: {
    subheadline: 'Für Kaifu Mitglieder',
    duration: '6 Monate gratis*',
    explainer:
      'Jetzt den Code „KAIFU“ nutzen und 6 Monate Zugriff auf die komplette Balloon- Bibliothek mit Kursen zu Themen wie Stress, Schlaf, Fokus und Klarheit erhalten.',
    button: 'Jetzt 6 Monate gratis Balloon nutzen'
  },
  kaifuHowTo: {
    title: 'So funktionierts',
    title_1: 'Registrieren',
    text_1: 'Einfach in den App-Store gehen, App herunterladen und registrieren.',
    title_2: 'Rabatt eingeben',
    title_3: 'Meditieren',
    text_3: '6 Monate Zugriff auf die komplette Balloon-Bibliothek erhalten.*',
    info: '*Bitte beachte: Das Abonnement verlängert sich automatisch nach 6 Monaten, wenn du nicht innerhalb dieses Zeitraums per Email bei Balloon kündigst.'
  },
  webplayer: {
    bannerText: 'Lass Balloon dein Leben leichter machen. ',
    bannerLink: 'Zum Angebot',
    iconNavLeft: 'Zurück',
    backButton: 'Zur Übersicht',
    rightDecorativeImage: 'Dekoratives Bild'
  },
  podcast: {
    title: 'Podcast',
    header: {
      headline: 'Verstehen, fühlen, glücklich sein',
      subline: 'DER ACHTSAMKEITS-PODCAST'
    },
    links_section: {
      headline: 'Gleich reinhören!',
      copy: 'Sinja Schütte und Dr. Boris Bornemann gehen in diesem Podcast den ganz großen Dingen im Kleinen nach. Themen wie Beziehungen, Angst, Freundschaft, Glück und Liebe werden aus der Perspektive der Achtsamkeit beleuchtet.',
      links_headline: "Hier geht's zur aktuellen Folge:",
      link_balloon: 'IN DER BALLOON APP HÖREN',
      link_balloon_download: 'APP HERUNTERLADEN',
      link_audionow: 'AUF AUDIO NOW ANHÖREN',
      link_audionow_url: 'https://audionow.de/podcast/verstehen-fuehlen-gluecklich-sein---der-achtsamkeitspodcast',
      link_spotify: 'AUF SPOTIFY ANHÖREN',
      link_spotify_url: 'https://open.spotify.com/show/35ju9frSvNCE4v4zBjCy5h',
      link_itunes: 'AUF ITUNES ANHÖREN',
      link_itunes_url:
        'https://podcasts.apple.com/de/podcast/verstehen-f%C3%BChlen-gl%C3%BCcklich-sein-der-achtsamkeitspodcast/id1462307407'
    },
    team_section: {
      headline: 'Das Team zum Podcast',
      subline: 'UNSERE EXPERTEN',
      boris: {
        name: 'Dr. Boris Bornemann',
        description:
          'Boris ist promovierter Neurowissenschaftler im Themenfeld der Meditation und Mitverfasser der weltweit größten wissenschaftlichen Studie zu Meditation.'
      },
      sinja: {
        name: 'Sinja Schütte',
        description:
          'Sinja hat ein untrügliches Gespür für gesellschaftliche Trends und weiß, wie daraus kreative Magazinkonzepte entstehen. Sie ist Chefredakteurin der Magazine FLOW, LIVING AT HOME+HOLLY sowie HYGGE.'
      }
    },
    episode_section: {
      headline: 'Ausgewählte Folgen',
      subline: 'DAS ERWARTET DICH',
      button: 'JETZT REINHÖREN',
      link_balloon: 'IN DER BALLOON APP HÖREN',
      link_audionow: 'AUF AUDIO NOW ANHÖREN',
      link_spotify: 'AUF SPOTIFY ANHÖREN',
      link_itunes: 'AUF ITUNES ANHÖREN',

      episode_1: {
        episode: 'Folge 1',
        headline: 'Achtsamkeit',
        short_description: 'Wie wir glücklicher und ent-spannter durchs Leben gehen.',
        description:
          'Wie wir glücklicher und ent-spannter durchs Leben gehen. In der ersten Folge werden wir grundsätzlich: Was ist Achtsamkeit eigentlich? Was hat das Meditation zu tun? Und was hat Meditation mit dem Autopiloten zu tun? Boris und Sinja geben einen ersten Überblick über das Thema.',
        link_balloon_url: 'balloon://view/podcast/2C4CCA94B046CE2D4953F356F249A0F87F50557EC4A1C57E4090E738610BC8C0',
        link_audionow_url: 'https://audionow.de/podcast/verstehen-fuehlen-gluecklich-sein---der-achtsamkeitspodcast',
        link_spotify_url: 'https://open.spotify.com/episode/0x7vBzFMmLo48RsKhpTt4i',
        link_itunes_url: 'https://podcasts.apple.com/de/podcast/1-achtsamkeit/id1462307407?i=1000437063989'
      },
      episode_3: {
        episode: 'Folge 3',
        headline: 'Besser schlafen',
        short_description: 'Wie Meditation uns helfen kann, besser zu schlafen.',
        description:
          'Wie Meditation uns helfen kann, besser zu schlafen. Wir alle kennen das: Es ist Nacht, wir kommen nicht zur Ruhe und die Gedanken kreisen – und wir werden unruhig und denken daran, wie es uns am nächsten Tag gehen mag. In dieser Folge geht es genau darum: Ums einschlafen, durchschlafen und wach liegen - und darum, wie wir das Gedankenkarussell stoppen können.',
        link_balloon_url: 'balloon://view/podcast/2C4CCA94B046CE2D4953F356F249A0F87F50557EC4A1C57E261ADCEF0629473B',
        link_audionow_url: 'https://audionow.de/podcast/verstehen-fuehlen-gluecklich-sein---der-achtsamkeitspodcast',
        link_spotify_url: 'https://open.spotify.com/episode/1H5ax0wp1A22tWutK4ehRk',
        link_itunes_url: 'https://podcasts.apple.com/de/podcast/3-besser-schlafen/id1462307407?i=1000438052498'
      },
      episode_33: {
        episode: 'Folge 33',
        headline: 'Alleinsein',
        short_description: 'Wie wir sensibel für uns und die Welt werden.',
        description:
          'Wie wir sensibel für uns und die Welt werden. Alleine sein und einsam sein. Zwischen diesen Extremen bewegen wir uns. Während für die einen “endlich alleine sein” ein Wunsch ist, der alltäglichen Hektik zu entkommen, ist es für andere, gerade auch ältere Menschen, ein großes Problem: Sie fühlen sich sozial nicht verbunden und einsam.\nSinja und Boris gehen in dieser Folge der Frage nach, warum es uns schwer fällt, alleine zu sein, warum wir uns trotzdem manchmal danach sehnen - und warum es in England mittlerweile ein “Ministerium für Einsamkeit” gibt.',
        link_balloon_url: 'balloon://view/podcast/2C4CCA94B046CE2D4953F356F249A0F87F50557EC4A1C57E5DE5775A05D9E50C',
        link_audionow_url: 'https://audionow.de/podcast/verstehen-fuehlen-gluecklich-sein---der-achtsamkeitspodcast',
        link_spotify_url: 'https://open.spotify.com/episode/2xGre8El5ix3PaepyMaq4y',
        link_itunes_url: 'https://podcasts.apple.com/de/podcast/33-alleinsein/id1462307407?i=1000460644499'
      }
    }
  },
  voucher_manual: {
    title: 'Gutschein',
    headline: 'Gutschein einlösen',
    description: 'So funktioniert´s',
    step_1:
      'Hast du noch kein Konto? Dann registriere dich zunächst auf unserer Website. Hast du bereits ein Konto? Dann klicke direkt auf "Anmelden“.',
    step_2: 'Gehe in deinen Profilbereich. Klicke hierzu oben rechts auf deinen Namen und dann "Mein Profil“.',
    step_3: 'In deinem Profil klickst du auf "Gutschein einlösen" und gibst deinen Gutscheincode im Eingabefeld ein.',
    step_4: 'Anschließend klickst du auf „Absenden“.',
    step_4_green: 'Jetzt hast du Zugriff auf die gesamte Balloon-Bibliothek!',
    subheadline: 'Viel Spaß beim Meditieren!',
    btn_text: 'Jetzt gutschein einlösen'
  },
  company: {
    contactBtn: 'KONTAKTIERE UNS',
    header: {
      headline: 'Gute Arbeit kommt\n von glücklichen\n Menschen',
      body: 'In der Praxis erprobt und wissenschaftlich fundiert: Die Meditations-App Balloon bringt Achtsamkeit in deine Unternehmenskultur.',
      offerBtn: 'UNSERE ANGEBOTE'
    },
    companyTrust: {
      headline: 'Diese Unternehmen vertrauen Balloon:'
    },
    benefit: {
      headline: 'So macht Balloon dein\n Unternehmen stärker',
      meditation: 'Meditation für alle:\n Einfach loslegen',
      happynes: 'Mit Achtsamkeit zu\n mehr Zufriedenheit',
      health: 'Gesundheit fördern,\n Kosten reduzieren'
    },
    audioSamples: {
      btn: {
        jobBtn: 'IM JOB ENTFALTEN',
        resilienzBtn: 'RESILIENZ STÄRKEN',
        stressBtn: 'GEGEN STRESS',
        sleepBtn: 'BESSER SCHLAFEN',
        audioBtn: 'HÖRPROBE'
      },
      job: {
        headline: 'Mehr Erfolg im Arbeitsalltag',
        body: 'Kurse und Übungen, die den Arbeitsalltag\n leichter machen',
        li_1: 'Fokus finden und Arbeitszeit sinnvoll nutzen',
        li_2: 'Eigene Interessen erkennen und Talente fördern',
        li_3: 'Den eigenen Körper verstehen und bewusst Pause machen'
      },
      resilienz: {
        headline: 'Ein Schutzschild für alle\n Mitarbeitenden',
        body: 'Kurse und Übungen für eine nachhaltige\n Arbeitskultur',
        li_1: 'Auch in turbulenten Zeiten Gelassenheit bewahren',
        li_2: 'Nach Krise und Stress schneller Erholung finden',
        li_3: 'An Herausforderungen wachsen'
      },
      stress: {
        headline: 'Klare Sicht für gute Arbeit',
        body: 'Schnelle Hilfen, Kurse und Übungen für einen\n guten Umgang mit Stress',
        li_1: 'Verstehen, was Stress im Körper auslöst',
        li_2: 'Richtig planen und übermäßigem Stress vorbeugen',
        li_3: 'Besser loslassen und Erholung fördern'
      },
      sleep: {
        headline: 'Erholsame Nächte für\n erfolgreiches Tagesgeschäft',
        body: 'Vor und nach Feierabend sorgen diese Inhalte\n für besseren Schlaf in der Nacht',
        li_1: 'Den eigenen Schlaf verstehen und kennenlernen',
        li_2: 'Leichter Entspannung finden – tagsüber und am Abend',
        li_3: 'Erholt und gelassen aufwachen'
      }
    },
    author: {
      headline_1: 'Kopf und Stimme von Balloon',
      headline_2: 'Dr. Boris Bornemann',
      body_1:
        'Dr. Bornemann ist promovierter Psychologe und Neurowissenschaftler. Er gehört zu Deutschlands führenden Achtsamkeitsexperten. Er steckt hinter den meisten Inhalten in der Balloon-App.',
      body_2:
        'Boris wird von Expert:innen aus Forschung, Wirtschaft und verschiedenen achtsamkeitsbasierten Verfahren unterstützt. Mit ihrem Fachwissen und ihrer Erfahrung aus der Praxis vervollständigen sie unser Angebot.'
    },
    offer: {
      headline: 'Unser Angebot für dein Unternehmen',
      subline: 'Wähle die Bausteine, die dich wirklich weiterbringen',
      card_1: {
        headline: 'Meditation für alle',
        li_1: 'Firmenlizenzen',
        li_2: 'Kuratierte Inhalte',
        li_3: 'Einfach einlösen und loslegen'
      },
      card_2: {
        headline: 'Effekte verstehen',
        li_1: 'Datenauswertung',
        li_2: 'Qualitative Umfragen',
        li_3: 'Regelmäßiger Austausch'
      },
      card_3: {
        headline: 'Zusammen loslegen',
        li_1: 'Live-Einführungsevent',
        li_2: 'Mit Dr. Boris Bornemann',
        li_3: 'App-Vorstellung und mehr'
      },
      card_4: {
        headline: '360° Achtsamkeit',
        li_1: 'Kommunikationspakete',
        li_2: 'Infotexte, Kolumnen und mehr',
        li_3: 'Individuell zusammengestellt'
      },
      card_5: {
        headline: "Hilfe, wenn's klemmt",
        li_1: 'Direkte Ansprechperson',
        li_2: 'Eine Adresse für alle Fragen',
        li_3: 'Jede Anfrage willkommen'
      },
      card_6: {
        headline: 'Unter deiner Flagge',
        li_1: 'Eigene Landingpage',
        li_2: 'Logointegration',
        li_3: 'Individualisierte Inhalte'
      }
    },
    partnerships: {
      headline: 'Erfolgreiche Partnerschaften',
      subline: 'Hier macht Balloon die Arbeit leichter:',
      card_swiss: {
        bold: '„In unserem Team meditieren wir wöchentlich mit der Balloon-App.\n',
        body: 'Wir befassen uns dabei mit Themen wie leichteres oder konzentrierteres Arbeiten und lernen konkrete Maßnahmen kennen, um im Arbeitsalltag fokussierter, effizienter und gelassener zu werden – und das alles wissenschaftlich fundiert.”',
        name: 'Mélina Zaugg',
        position: 'Leiterin Vertriebskommunikation',
        company: 'Swiss Life AG'
      },
      card_xing: {
        bold: '„Wir sind großer Balloon Fan!\n',
        body: 'Ob für die guided-meditation @home, für eine Entspannungsminute in der Bahn oder für die wöchentliche Achtsamkeitsübung mit dem Team - Balloon unterstützt uns dabei, fokussiert und achtsam im Alltag zu sein.”',
        name: 'Geraldine Schachtel',
        position: 'Director People Growth',
        company: 'XING, New Work SE'
      },
      card_hansefit: {
        bold: '"Wir freuen uns sehr, die Balloon-App in unserem Firmenfitness-Angebot Hansefit anzubieten.\n',
        body: 'Durch das Meditationsangebot können unsere Mitglieder von überall aus kleine Meditations- und Achtsamkeitsinseln in ihren beruflichen und privaten Alltag einbauen. Für uns als Anbieter von Firmensport eine fantastische Ergänzung für den Bereich Mental Health.”',
        name: 'Matthias Ortmeyer',
        position: 'Marketing Director',
        company: 'Hansefit GmbH & Co. KG'
      }
    },
    health_advisor: {
      claim: 'Du berätst Menschen in Gesundheitsfragen? Wir haben ein Angebot für dich. ',
      link: ' Jetzt entdecken'
    },
    whitepaper: {
      headline: 'Kostenloses Whitepaper:\n Resilienz',
      body: 'Umbruch und Veränderung prägen die Arbeitswelten der Gegenwart. Die achtsame Antwort darauf: Resilienz. In unserem Whitepaper für Unternehmen geht es um die Förderung von Resilienz im (Arbeits-) Alltag. Entdecke deine Möglichkeiten!',
      form: 'Deine Kontaktdaten',
      asterixText: 'Kontaktdaten teilen und Whitepaper erhalten.\n Es gelten unsere ',
      privacyPolicyLinkText: 'Datenschutzbestimmungen',
      zu: '.',
      success: {
        headline: 'Vielen Dank für deine Anfrage!',
        body_1: 'Das kostenlose Whitepaper wird direkt an deine E-Mail-Adresse gesendet.',
        body_2: 'Unser Team meldet sich bei dir.'
      }
    },
    brands: {
      headline: 'Bekannt aus'
    },
    faq: {
      headline: 'Häufige Fragen',
      headline_1: 'Wie erhalten meine Mitarbeitenden Zugriff auf die Balloon App?',
      body_1a: '1. Deine Mitarbeitenden registrieren sich auf der ',
      body_1a_linkText: 'Balloon Homepage',
      body_1b: '\n2. Vom Unternehmen erhalten sie einen Voucher Code, der auf der Homepage in ',
      body_1b_linkText: 'ihrem Profil ',
      body_1c: `eingelöst werden kann.
    3. Die Balloon App kann dann im AppStore oder Google Playstore downgeloadet werden und deine Mitarbeitenden können sich direkt anmelden und ihren freien Zugriff auf alle Premium Inhalte ausnutzen.`,
      headline_2: 'Gibt es versteckte Kosten oder eine automatische Aboverlängerung für meine Mitarbeitenden?',
      body_2:
        'Die Einlösung eines Voucher Codes schaltet alle Inhalte der Balloon App für den jeweiligen Zeitraum vollständig frei. Es gibt keine automatischen Verlängerungen und der Zugang muss auch nicht gekündigt werden, sondern läuft automatisch aus.',

      headline_3: 'Ist die Balloon App nur auf deutsch verfügbar?',
      body_3: 'Momentan ist die Balloon App ausschließlich im DACH-Raum und in deutscher Sprache verfügbar.'
    },
    form: {
      placeholderMail: 'E-Mail-Adresse *',
      valMissingPhone: 'Bitte gib deine Telefonnummer ein.',
      valMissingCompanyName: 'Bitte gib den Namen deines Unternehmens ein.',
      valMissingCompanySize: 'Bitte wähle die Anzahl der Mitarbeiter aus.',
      valMissingJobTitle: 'Bitte gib deine Position ein.'
    }
  },
  healthAdvisor: {
    collaborationOffer: {
      headline: 'Gesundheit ist dein täglich Brot?',
      body: 'Wie schön, dann lass uns zusammenarbeiten: Als Ärztin oder Therapeut, Heilpraktikerin oder Coach nutzt du Balloon ein Jahr lang kostenfrei.',
      button: 'Jetzt anfragen'
    },
    collaborationBenefit: {
      headline: 'So ergänzt Balloon deine Behandlung',
      coach: 'Dein Co-Coach:',
      support: 'Unterstützung zum Mitnehmen',
      base: 'Die Basis:',
      science: 'Wissenschaftlich fundiert',
      mile: 'Die Extrameile:',
      assistance: 'Betreuung über das Problem hinaus'
    },
    inquiry: {
      headline: 'Weil du mehr brauchst',
      subtitle: 'Erlebe alle Möglichkeiten der Balloon-App. Deine Vorteile:',
      form: 'Deine Kontaktdaten',
      asterixText: 'Anfrage senden und dein Balloon-Zugangs-Paket erhalten. Es gelten unsere ',
      privacyPolicyLinkText: 'Datenschutzbestimmungen',
      zu: '.',
      li_1: 'Kostenfreier Jahreszugang',
      li_2: 'Studienergebnisse, Bonus-Inhalte',
      li_3: 'Praxishinweise für deine Arbeit',
      li_4: 'Infomaterial für deine Klientinnen und Klienten.',
      body: 'Wir freuen uns auf deine Anfrage!',
      success: {
        headline: 'Vielen Dank für deine Anfrage!',
        body: 'Unser Team wird sich in Kürze mit dir in Verbindung setzen.'
      },
      placeholderName: 'Vor- und Nachname',
      placeholderOffice: 'Name der Praxis',
      requiredOffice: 'Bitte gib den Namen deiner Praxis ein.',
      placeholderUrl: 'Website',
      requiredUrl: 'Bitte gib deine Website ein.',
      placeholderMessage: 'Deine Nachricht an uns (optional)'
    },
    faq: {
      headline: 'Häufige Fragen',
      headline_1: 'Wer kann dieses Angebot wahrnehmen?',
      body_1:
        'Dieses Angebot ist für dich, wenn du als Ärztin oder Arzt, Psychologin oder Psychologe, Therapeutin oder Therapeut, Heilpraktikerin oder Heilpraktiker oder Coach Menschen im Bereich der psychischen oder körperlichen Gesundheit berätst.',
      headline_2: 'Was ist mein Vorteil?',
      body_2: {
        li_1: 'Du bekommst einen kostenfreien Jahreszugang zur Balloon-App und zu unserem erstattungsfähigen Präventionskurs „Stressreduktion durch Achtsamkeit“, so kannst du die App ausgiebig kennenlernen und unsere Inhalte auf Herz und Nieren prüfen.',
        li_2: 'Im weiteren Infomaterial bekommst du die Möglichket, noch mehr über die positiven Effekte von Achtsamkeit, über die wissenschaftlich nachgewiesene Wirksamkeit der Balloon-App und über unser Konzept zu erfahren.',
        li_3: 'Zusätzlich stellen wir dir Infomaterial für deine Klientinnen und Klienten zur Verfügung. Wir freuen uns, wenn du die Balloon-App weiterempfiehlst!'
      }
    }
  },
  bkk: {
    header: {
      headline: 'Schön, dass du dich für Balloon entschieden hast!',
      subline:
        'Starte jetzt mit Balloon und der Bertelsmann BKK den Kurs "Stressreduktion durch Achtsamkeit" und erhalte 3 Monate alle Inhalte der Balloon App gratis dazu.',
      button: 'Jetzt Starten'
    },
    checkmark_section: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Die Achtsamkeits App Balloon sorgt für Gesundheit, Klarheit und Gelassenheit. Die audiobasierten Kursübungen verbinden Wissen und gewohnheitsbildende Praxis zu Themen wie Stress, Gedanken, Gefühlen und Gelassenheit.',
      subline_2:
        'Kopf und Stimme der Balloon App ist Dr. Boris Bornemann. Der promovierte Psychologe und Meditationsforscher gehört zu Deutschlands führenden Achtsamkeits-Expert:innen. Er stellt sicher, dass der Kurs wissenschaftlich Hand und Fuß hat und hält, was er verspricht.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder unterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen'
    },
    ballooni_section: {
      headline: 'Als Versicherte der Bertelsmann BKK bekommst du ganz einfach die Kosten erstattet.',
      ballooni_1:
        'Kaufe auf dieser Seite den Kurs "Stressreduktion durch Achtsamkeit" für 12 Monate und erhalte alle Inhalte der Balloon App für 3 Monate kostenlos dazu.',
      ballooni_2: 'Du erhältst die Rechnung zu deinem Kauf per E-Mail. Diese reichst du anschließend bei der Bertelsmann BKK ein.',
      ballooni_3: 'Die Kosten für den Kauf werden über dein Bonusprogramm bei der Bertelsmann BKK vollständig erstattet.'
    },
    form_section: {
      checkbox:
        'Hiermit bestätige ich, dass ich Versicherte/r der Bertelsmann BKK bin. Ich bin damit einverstanden, dass die Gruner + Jahr Deutschland GmbH Vor- und Nachnamen zum Abgleich an die Bertelsmann BKK übergibt, um unberechtigte Zugriffe zu unterbinden.'
    },
    buy_box_section: {
      headline: 'Dein Balloon Paket ist 100% erstattungsfähig.',
      description:
        'In diesem Paket enthalten sind 12 Monate Zugriff auf den Kurs "Stressreduktion durch Achtsamkeit" + 3 Monate kostenloser Zugang zu allen Premium Inhalten der Balloon App.',
      relator: '*Die Kosten kannst du dir bei der Bertelsmann BKK  erstatten lassen.'
    }
  },
  insuranceCooperation: {
    popupNotOpened: 'Das Popup-Fenster konnte nicht geöffnet werden. Bitte überprüfe die Popup-Einstellungen deines Browsers.',
    authSection: {
      errors: {
        defaultError: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut, oder wende dich an unseren Support.',
        transferFailed: 'Bei der Bestätigung deiner Anmeldung ist etwas schief gegangen. Bitte wende dich an unseren Support.',
        NOT_ALLOWED: 'Die Mitgliedschaft konnte nicht bestätigt werden. Bitte versuche es erneut, oder wende dich an unseren Support.'
      }
    }
  },
  bigDirect: {
    header: {
      headline: 'Deine Reise mit Balloon und der BIG direkt gesund',
      subline:
        'Starte jetzt den Kurs "Stressfrei & achtsam leben" und sichere dir im Anschluss 12 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'BIG direkt gesund Logo',
      logoLink: 'https://www.big-direkt.de/',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der BIG erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder\nunterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für Versicherte der BIG\nist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich auf dieser Seite bei Balloon: Gib deinen Namen, deine E-Mail-Adresse, deine Versichertennummer und dein Geburtsdatum an.',
      ballooni_2:
        'Lade dir die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "Stressfrei & achtsam leben" gilt für 6 Monate.',
      ballooni_3:
        'Absolviere den Kurs vollständig und erhalte anschließend ein Teilnahme-Zertifikat sowie 12 Monate Vollzugriff auf die Balloon-App. Als BIG-Versicherter darfst du pro Kalenderjahr an zwei Präventions-Programmen teilnehmen.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte von\nBIG direkt gesund',
      infoText:
        'Registriere dich hier mit deiner BIG-Versichertennummer und schalte den Kurs "Stressfrei & achtsam leben" für 6 Monate frei. Bei Fragen wende dich gerne an\n',
      contactMail: 'bigdirektgesund@balloonapp.de',
      placeholderNum: 'BIG direkt gesund-Versichertennummer*',
      placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
      placeholderName: 'Vor- und Nachname *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die BIG direkt gesund übermittelt werden.',
      insuranceNumberInfo: 'Deine BIG direkt gesund -Versichertennummer findest du auf deiner Krankenkassenkarte.'
    },
    registerUserWithOtp: {
      unknownUserHeadline: 'E-Mail Registrierung bestätigen',
      unknownUserInfotest_1: 'Gib hier bitte den 6-stelligen Code ein, den du per E-Mail bekommen hast. Er wurde an ',
      unknownUserInfotest_2: ' verschickt.',
      resendCode: ' Neuen Code anfordern',
      submitCode: 'Code abschicken',
      invalidCode: 'Code ist nicht gültig'
    }
  },
  salusBkk: {
    header: {
      headline: 'Deine Reise mit Balloon\nund der Salus BKK',
      subline:
        'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" und sichere dir im Anschluss 6 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'Salus BKK Logo',
      logoLink: 'https://www.salus-bkk.de/',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der Salus BKK erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder\nunterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für Versicherte der Salus BKK\nist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
      ballooni_2:
        'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "Stressreduktion durch Achtsamkeit" gilt für 6 Monate.',
      ballooni_3:
        'Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die Salus BKK sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für 6 Monate.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte\nvon der Salus BKK',
      infoText:
        'Registriere dich hier mit deiner Salus BKK-Versichertennummer und schalte den Kurs "Stressreduktion durch Achtsamkeit" für 6 Monate frei. Bei Fragen wende dich gerne an\n',
      contactMail: 'salusbkk@balloonapp.de',
      placeholderNum: 'Salus BKK-Versichertennummer*',
      placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
      placeholderName: 'Vor- und Nachname *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die Salus BKK übermittelt werden.',
      insuranceNumberInfo: 'Deine Salus BKK -Versichertennummer findest du auf deiner Krankenkassenkarte.'
    },
    registerUserWithOtp: {
      unknownUserHeadline: 'E-Mail Registrierung bestätigen',
      unknownUserInfotest_1: 'Gib hier bitte den 6-stelligen Code ein, den du per E-Mail bekommen hast. Er wurde an ',
      unknownUserInfotest_2: ' verschickt.',
      resendCode: ' Neuen Code anfordern',
      submitCode: 'Code abschicken',
      invalidCode: 'Code ist nicht gültig'
    }
  },
  tk: {
    header: {
      headline: 'Deine Reise mit Balloon und der Techniker',
      subline:
        'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" und sichere dir im Anschluss 12 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'die Techniker Logo',
      logoLink: 'https://www.tk.de/',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der Techniker Krankenkasse erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder\nunterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen',
      altText: 'Kurs Stressreduktion durch Achtsamkeit'
    },
    ballooniSection: {
      headline: 'Für Versicherte der Techniker ist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
      ballooni_2:
        'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "Stressreduktion durch Achtsamkeit" gilt für 12 Monate.',
      ballooni_3:
        'Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die Techniker Krankenkasse sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für 12 Monate.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    authSection: {
      headline: 'Mitgliedschaft bei\nder Techniker bestätigen',
      headline_success: 'Mitgliedschaft bestätigt',
      headline_clock: 'Deine Bestätigung ist 12 Minuten lang,\nbis ##TIME## Uhr gültig',
      description:
        'Bestätige hier schnell und unkompliziert deine Mitgliedschaft\nbei der Techniker, um das Registrierungsformular freizuschalten!',
      description_success: 'Jetzt Formular ausfüllen und loslegen',
      authButton: 'Mitgliedschaft bestätigen',
      popupError: 'Das Login-Fenster konnte nicht geöffnet werden. Bitte überprüfe die Popup-Einstellungen deines Browsers.',
      errorHeadline: 'Mitgliedschaft konnte nicht bestätigt werden',
      errorDescription1: 'Tut uns leid, das ist etwas schief gelaufen. Bitte versuche es erneut:',
      errorDescription2:
        'Hier findest du Hilfe zum Anmelden bei der TK und Hilfe zum Thema Leistungsanspruch für Gesundheitskurse bei der TK.',
      errors: {
        defaultError: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut, oder wende dich an unseren Support.',
        NOT_ALLOWED: 'Die Mitgliedschaft konnte nicht bestätigt werden. Bitte versuche es erneut, oder wende dich an unseren Support.'
      }
    },
    formSection: {
      headline: 'Registrierung für Versicherte\n der Techniker Krankenkasse',
      infoText:
        'Registriere dich hier und schalte den Kurs "Stressreduktion durch Achtsamkeit" für 12 Monate frei. Bei Fragen wende dich gerne an\n',
      contactMail: 'techniker@balloonapp.de',
      placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
      placeholderName: 'Vor- und Nachname *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die Techniker übermittelt werden.'
    },
    registerUserWithOtp: {
      unknownUserHeadline: 'E-Mail Registrierung bestätigen',
      unknownUserInfotest_1: 'Gib hier bitte den 6-stelligen Code ein, den du per E-Mail bekommen hast. Er wurde an ',
      unknownUserInfotest_2: ' verschickt.',
      resendCode: ' Neuen Code anfordern',
      submitCode: 'Code abschicken',
      invalidCode: 'Code ist nicht gültig'
    }
  },
  mkk: {
    header: {
      headline: 'Deine Reise mit Balloon und der mkk - meine krankenkasse',
      subline:
        'Starte jetzt den Kurs „Mindful Me: Mein Weg zur inneren Balance” und sichere dir anschließend 6 Monate Vollzugriff auf die komplette Balloon-Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'mkk Logo',
      logoLink: 'https://www.meine-krankenkasse.de/',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Die App Balloon sorgt für Gesundheit, Klarheit und Gelassenheit. Für Versicherte der "mkk - meine krankenkasse" haben wir einen Kurs entwickelt, der dich dabei unterstützt, weniger gestresst durch das Leben zu gehen. In den Audioübungen wird Wissen mit gewohnheitsbildender Praxis verbunden.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
      checkmark_2: '10 Minuten am Tag - bequem in der\nApp, du bestimmst wann und wo',
      checkmark_3: 'Stress durch Achtsamkeit\nreduzieren und entspannter leben',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für mkk Versicherte \nist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
      ballooni_2:
        'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs „Mindful Me: Mein Weg zur inneren Balance“ gilt für 6 Monate.',
      ballooni_3:
        'Absolviere den Kurs vollständig und erhalte dein Teilnahmezertifikat als Nachweis für die mkk sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte\nder mkk - meine krankenkasse',
      infoText:
        'Registriere dich hier mit deiner mkk - Versichertennummer und schalte den Kurs “Mindful Me: Mein Weg zur inneren Balance” für 6 Monate frei. Bei Fragen wende dich gerne an\n',
      contactMail: 'mkk@balloonapp.de',
      placeholderNum: 'mkk-Versichertennummer*',
      placeholderName: 'Name *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die mkk übermittelt werden.',
      insuranceNumberInfo: 'Deine mkk-Versichertennummer findest du auf deiner Krankenkassenkarte.'
    }
  },
  dak: {
    header: {
      headline: 'Deine Reise mit Balloon und der DAK-Gesundheit',
      subline:
        'Starte jetzt den Kurs "DAK Antistress-Coaching \n(achtsamkeitsbasiert)" und sichere dir anschließend 3 Monate \nVollzugriff auf die komplette Balloon-Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'DAK-Gesundheit Logo',
      logoLink: 'https://dak.de',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Als Versicherte der DAK-Gesundheit erwartet dich in der App ein spezielles Antistress-Coaching. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
      checkmark_3: 'Herausforderungen mit \nResilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für Versicherte der DAK-Gesundheit \nist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich auf dieser Seite bei Balloon: Gib deinen Namen, deine E-Mail-Adresse und deine Versichertennummer der DAK-Gesundheit an.',
      ballooni_2:
        'Lade dir die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "DAK Antistress-Coaching (achtsamkeitsbasiert)" gilt für ein Jahr.',
      ballooni_3:
        'Absolviere den Kurs vollständig und erhalte anschließend ein Teilnahmezertifikat sowie 3 Monate Vollzugriff auf die Balloon-App.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte \nder DAK-Gesundheit',
      infoText:
        'Registriere dich hier mit deiner \nVersichertennummer der DAK-Gesundheit und schalte den Kurs "DAK Antistress-Coaching (achtsamkeitsbasiert)" \nfür ein Jahr frei. Bei Fragen wende dich gerne an ',
      contactMail: 'dak@balloonapp.de',
      placeholderNum: 'Versichertennummer *',
      placeholderName: 'Name *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die DAK übermittelt werden.',
      insuranceNumberInfo: 'Deine Versichertennummer findest du auf deiner Krankenkassenkarte.'
    }
  },
  dakBgm: {
    header: {
      headline: 'Deine Reise mit Balloon und der DAK-Gesundheit',
      subline:
        'Starte jetzt den Kurs "DAK Antistress-Coaching \n(achtsamkeitsbasiert)" und sichere dir anschließend 3 Monate \nVollzugriff auf die komplette Balloon-Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'DAK-Gesundheit Logo',
      logoLink: 'https://dak.de',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. In der App erwartet dich ein spezielles Antistress-Coaching. Es ist Teil des Betrieblichen Gesundheitsmanagements der DAK-Gesundheit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
      checkmark_3: 'Herausforderungen mit \nResilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Kostenlos im\n Betrieblichen Gesundheits-\nmanagement der \nDAK-Gesundheit enthalten',
      ballooni_1: 'Registriere dich auf dieser Seite bei Balloon: Gib deinen Namen, deine E-Mail-Adresse und den Namen deiner Firma an.',
      ballooni_2:
        'Lade dir die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "DAK Antistress-Coaching (achtsamkeitsbasiert)" gilt für ein Jahr.',
      ballooni_3: 'Absolviere den Kurs vollständig und erhalte 3 Monate Vollzugriff auf die Balloon-App.'
    },
    formSection: {
      headline: 'Registrierung mit dem \nBetrieblichen \nGesundheitsmanagement \nder DAK-Gesundheit',
      infoText:
        'Registriere dich hier mit dem Namen deiner Firma und schalte so den Kurs "DAK Antistress-Coaching (achtsamkeitsbasiert)" für ein Jahr frei. Bei Fragen wende dich gerne an ',
      contactMail: 'dak@balloonapp.de',
      placeholderName: 'Vor- und Nachname *',
      placeholderMail: 'E-Mail-Adresse *',
      postalCode: 'PLZ deines Wohnsitzes *',
      placeholderNum: '(optional) Versichertennummer',
      additionalInfoText: 'Wir versuchen, unser Angebot immer weiter zu verbessern. Dabei helfen uns folgende Informationen:',
      leftButton: 'DAK-Gesundheit',
      rightButton: 'Andere Versicherung',
      placeholderCompanyRequired: 'Firma *'
    },
    successScreen: {
      newsletterHeadline: 'Wollen Sie auch in Zukunft auf\n dem Laufenden bleiben?',
      newsletterBody:
        'Wir freuen uns, dass Ihnen Ihre Gesundheit so wichtig ist. Mit Ihrer Einwilligung kann Ihnen die DAK-Gesundheit künftig die Leistungen, Extras und Services vorstellen, die ganz persönlich zu Ihnen passen - natürlich kostenlos und unverbindlich.',
      eveButton: 'Einwilligung zur Kontaktaufnahme'
    }
  },
  ikkClassic: {
    header: {
      headline: 'Deine Reise mit Balloon und der IKK Classic',
      subline:
        'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" und sichere dir im Anschluss 6 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'IKK Classic Logo',
      logoLink: 'https://www.ikk-classic.de/',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der IKK Classic erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder\nunterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für Versicherte der IKK Classic\nist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
      ballooni_2:
        'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "Stressreduktion durch Achtsamkeit" gilt für 12 Monate.',
      ballooni_3:
        'Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die IKK Classic sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für 6 Monate.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte\nvon der IKK Classic',
      infoText:
        'Registriere dich hier mit deiner IKK Classic-Versichertennummer und schalte den Kurs "Stressreduktion durch Achtsamkeit" für 12 Monate frei. Bei Fragen wende dich gerne an\n',
      contactMail: 'ikkclassic@balloonapp.de',
      placeholderNum: 'IKK Classic-Versichertennummer*',
      placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
      placeholderName: 'Vor- und Nachname *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die IKK Classic übermittelt werden.',
      insuranceNumberInfo: 'Deine IKK Classic -Versichertennummer findest du auf deiner Krankenkassenkarte.'
    }
  },
  hkk: {
    header: {
      headline: 'Deine Reise mit\nBalloon und der hkk',
      subline:
        'Starte jetzt den Kurs "Stressreduktion durch Meditation & Achtsamkeit" und sichere dir im Anschluss 6 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'hkk Logo',
      logoLink: 'https://www.hkk.de/',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der hkk erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder\nunterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für Versicherte der hkk\nist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
      ballooni_2:
        'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "Stressreduktion durch Meditation & Achtsamkeit" gilt für 12 Monate.',
      ballooni_3:
        'Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die hkk sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für 6 Monate.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte\nder hkk',
      infoText:
        'Registriere dich hier mit deiner hkk-Versichertennummer und schalte den Kurs "Stressreduktion durch Meditation & Achtsamkeit" für 12 Monate frei. Bei Fragen wende dich gerne an\n',
      contactMail: 'hkk@balloonapp.de',
      placeholderNum: 'hkk -Versichertennummer*',
      placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
      placeholderName: 'Vor- und Nachname *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die hkk übermittelt werden.',
      insuranceNumberInfo:
        'Deine hkk-Versichertennummer findest du auf deiner Krankenkassenkarte. Sie beginnt mit einem Buchstaben und 9 Zahlen.'
    }
  },
  vividaBkk: {
    header: {
      headline: 'Deine Reise mit Balloon \nund der vivida bkk',
      subline:
        'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" und sichere dir im Anschluss 6 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'vivida bkk Logo',
      logoLink: 'https://www.vividabkk.de/de/',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der vivida bkk erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder\nunterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für Versicherte der vivida bkk\nist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
      ballooni_2:
        'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs "Stressreduktion durch Achtsamkeit" gilt für 12 Monate.',
      ballooni_3:
        'Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die vivida bkk sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für 6 Monate.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte\nder vivida bkk',
      infoText:
        'Registriere dich hier mit deiner vivida bkk-Versichertennummer und schalte den Kurs "Stressreduktion durch Achtsamkeit" für 12 Monate frei. Bei Fragen wende dich gerne an\n',
      contactMail: 'vividabkk@balloonapp.de',
      placeholderNum: 'vivida bkk-Versichertennummer*',
      placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
      placeholderName: 'Vor- und Nachname *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die vivida bkk übermittelt werden.',
      insuranceNumberInfo: 'Deine vivida bkk-Versichertennummer findest du auf deiner Krankenkassenkarte.'
    }
  },
  aokBayern: {
    header: {
      headline: 'Deine Reise mit Balloon und der AOK Bayern',
      subline:
        'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" und sichere dir im Anschluss 6 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'AOK-Bayern Logo',
      logoLink: 'https://www.aok.de/pk/bayern',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Balloon sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit. Versicherte der AOK Bayern erwartet in der App ein spezielles Kursprogramm zur Stressreduktion durch Achtsamkeit. Die Audio-Einheiten des Kurses begleiten dich Schritt für Schritt in einen entspannteren Alltag.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für Versicherte der AOK Bayern\n ist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.',
      ballooni_2:
        'Lade dir anschließend die Balloon-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den Kurs „Stressreduktion durch Achtsamkeit“ gilt für 12 Monate.',
      ballooni_3:
        'Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die AOK Bayern sowie deinen Vollzugriff auf alle Inhalte der Balloon Bibliothek für 6 Monate.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte\nder AOK Bayern',
      infoText:
        'Registriere dich hier mit deiner AOK Bayern - Versichertennummer und schalte den Kurs “Stressreduktion durch Achtsamkeit” für 12 Monate frei. Bei Fragen wende dich gerne an\n',
      contactMail: 'aok-bayern@balloonapp.de',
      placeholderNum: 'AOK Bayern-Versichertennummer*',
      placeholderName: 'Name *',
      placeholderMail: 'E-Mail-Adresse *',
      placeholderDateOfBirth: 'Geburtsdatum* (TT.MM.JJJJ)',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die AOK Bayern übermittelt werden.',
      insuranceNumberInfo: 'Deine AOK Bayern-Versichertennummer findest du auf deiner Krankenkassenkarte.'
    }
  },
  genderOptions: {
    unknown: '(optional) Geschlecht',
    female: 'weiblich',
    male: 'männlich',
    divers: 'divers'
  },

  formsGeneral: {
    acceptText: 'Ich akzeptiere die ',
    andText: ' und die ',
    buttonTextHealthRequirements: 'Gesundheitsvoraussetzungen',
    buttonTextPrivacyPolicy: 'Hinweise zum Datenschutz',
    buttonTextTermsOfUse: 'AGB',
    companyName: 'Name des Unternehmens *',
    companySize: 'Anzahl Mitarbeitende *',
    confirmHealthTermsText: ' und bestätige hiermit, dass keine Kontraindikationen für die Absolvierung des Kurses vorliegen.',
    customErrorNum: 'custom error',
    infoTextLoginInsuranceNumber:
      'Mit dieser Versichertennummer wurde bereits ein Account registriert. Melde dich damit an, um zu deinem Account zu gelangen.',
    insuranceNumberAlreadyExists: 'Bitte überprüfe deine Versichertennummer.',
    insuranceNumberInfo: 'Deine Versichertennummer findest du auf deiner Krankenkassenkarte.',
    insuranceNumberInvalid: 'Die Versicherungsnummer beginnt mit einem Großbuchstaben gefolgt von 9 Ziffern',
    isTrimmed: 'Bitte entferne überflüssige Leerzeichen.',
    jobTitle: 'Position *',
    legalNewsletterInfo:
      'Als Balloon-Nutzer:in erhältst du Achtsamkeits-Tipps, Angebote und Umfragen rund um Balloon per E-Mail. Du hast jederzeit die Möglichkeit, über einen Link am Ende der E-Mail zu widersprechen.',
    message: 'Sag uns, wie wir dein Team unterstützen können (optional)',
    phone: 'Telefonnummer *',
    placeholderAge: '(optional) Alter',
    placeholderCompany: 'Firma *',
    placeholderGender: '(optional) Geschlecht',
    placeholderMail: 'E-Mail *',
    placeholderVornameNachname: 'Vor- und Nachname *',
    precondition: 'Hiermit bestätige ich, dass ich in diesem Kalenderjahr noch nicht an zwei Präventionskursen teilgenommen habe.',
    requiredCheckbox: 'Bitte akzeptiere die Erklärung.',
    requiredEmail: 'Bitte gib deine E-Mail ein.',
    requiredHealthCheck: 'Bitte akzeptiere die Gesundheitsvoraussetzungen.',
    requiredDateOfBirth: 'Gib bitte dein Geburtsdatum ein. (TT.MM.JJJJ)',
    requiredPostalCode: 'Bitte gib die Postleitzahl deines Wohnsitzes an',
    requiredName: 'Bitte gib deinen Namen ein.',
    requiredPrecondition: 'Bitte akzeptiere die Voraussetzungen.',
    requiredGender: 'Bitte wählen dein Geschlecht aus.',
    requiredAge: 'Bitte gebe dein Alter an.',
    ageToHigh: 'Bitte kontrolliere deine Altersangabe.',
    ageToLow: 'Bitte kontrolliere deine Altersangabe.',
    requiredTermsCheck: 'Bitte akzeptiere die AGB.',
    requiredPostalCodeCheck: 'Hiermit bestätige ich, dass ich aktuell wohnhaft in Deutschland bin.',
    sendBtn: 'ABSCHICKEN',
    validDateOfBirth: 'Bitte gib dein Geburtsdatum an. (TT.MM.JJJJ)',
    validEmail: 'Keine valide E-Mail-Adresse.',
    validInsuranceNumber: 'Keine valide Versichertennummer.',
    validPostalCode: 'Deine PLZ entspricht keiner deutschen Postleitzahl, bitte überprüfe deine Eingabe.',
    validPostalCodeCheck: 'Bitte bestätige, dass du in Deutschland wohnhaft bist.',
    validLengthNum: 'Bitte gib eine gültige Versichertennummer ein.',
    validUrl: 'Keine valide URL.',
    valueMissingAgrees: 'Du musst dem Datenabgleich zustimmen.',
    valueMissingCompany: 'Bitte gib deinen Arbeitgeber ein.',
    valueMissingName: 'Bitte gib deinen Namen ein.',
    valueMissingNum: 'value missing',
    registerUserWithOtp: {
      unknownUserHeadline: 'E-Mail Registrierung bestätigen',
      unknownUserInfotest_1: 'Gib hier bitte den 6-stelligen Code ein, den du per E-Mail bekommen hast. Er wurde an ',
      unknownUserInfotest_2: ' verschickt.',
      resendCode: ' Neuen Code anfordern',
      submitCode: 'Code abschicken',
      invalidCode: 'Code ist nicht gültig'
    },
    successScreen: {
      openApp: 'App öffnen',
      successHeadline: 'Super, das hat geklappt!',
      successInfotext:
        'Öffne jetzt die Balloon-App und melde dich dort mit deiner hier angegebenen Mail-Adresse an. Für die App-Anmeldung erhältst du erneut einen Einmalcode zum Eingeben.',
      successHeadlineWelcomeBack: 'Willkommen zurück!',
      successInfotextWelcomeBack:
        'Du hast noch eine laufende Kurs-Freischaltung. Du bist daher aktuell noch nicht berechtigt, den Kurs erneut zu starten. Du\n kannst aber einfach wieder in die Balloon-App zurück kehren und den Kurs fortsetzen!'
    }
  },
  viactiv: {
    header: {
      headline: 'Deine Reise mit Balloon \nund der VIACTIV',
      subline:
        'Starte jetzt den Kurs "Stressreduktion durch Achtsamkeit" \n und sichere dir im Anschluss 6 Monate Vollzugriff auf die komplette Balloon Bibliothek.',
      button: 'Jetzt Starten',
      altText: 'VIACTIV Logo',
      logoLink: 'https://www.viactiv.de/',
      addOnText: 'Ein Online-Coaching von Balloon'
    },
    checkmarkSection: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Die App Balloon sorgt für Gesundheit, Klarheit und Gelassenheit. Für Kunden der VIACTIV haben wir einen Kurs entwickelt, mit dem du weniger gestresst durch das Leben gehen kannst. In den audiobasierten Kursübungen wird Wissen mit gewohnheitsbildender Praxis verbunden.',
      subline_2: 'Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und \nFortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im \nHomeoffice oder unterwegs',
      checkmark_3: 'Herausforderungen mit \nResilienz begegnen',
      altText: 'Kurs Antistress-Coaching'
    },
    ballooniSection: {
      headline: 'Für VIACTIV-Versicherte \nist der Kurs kostenlos.',
      ballooni_1:
        'Registriere dich einfach unten auf dieser Website. Beachte: Du musst mind. 100% des Kurses absolvieren und darfst dein Präventionsbudget von 550€ im Jahr noch nicht ausgeschöpft haben.',
      ballooni_2:
        'Lade dir anschließend die Balloon-App herunter und melde dich mit \ndeiner E-Mail-Adresse an. \nDein kostenloser Zugriff auf den Kurs "Stressreduktion durch Achtsamkeit"\n gilt für ein Jahr.',
      ballooni_3:
        'Absolviere den Kurs vollständig \ninnerhalb von 6 Monaten und \nerhalte anschließend \ndein Zertifikat zur Teilnahme \nam VIACTIV-Bonusprogramm. \nGenieße außerdem nach \nKursabschluss 6 Monate \nVollzugriff auf die gesamte \nBalloon-App.'
    },
    trustBandSection: { headline: 'Balloon ist bekannt aus:' },
    formSection: {
      headline: 'Registrierung für Versicherte \nder VIACTIV',
      infoText:
        'Registriere dich hier mit deiner VIACTIV-\nVersichertennummer und schalte den Kurs \n"Stressreduktion durch Achtsamkeit" für ein Jahr \nfrei. Bei Fragen wende dich gerne an \n',
      contactMail: 'viactiv@balloonapp.de',
      placeholderNum: 'VIACTIV-Versichertennummer *',
      placeholderName: 'Name *',
      placeholderMail: 'E-Mail-Adresse *',
      personalDataSync:
        'Ich bin damit einverstanden, dass meine persönlichen Daten (Name, Versichertennummer und die Anzahl der absolvierten Kurseinheiten) an die VIACTIV übermittelt werden.'
    }
  },
  xing: {
    header: {
      headline: 'Weniger Stress, leichter arbeiten und die richtigen Strategien zum Dranbleiben!',
      subline: 'Im exklusiven Kurs-Paket von Balloon für XING Premium Mitglieder - starte jetzt deine Reise zu mehr Achtsamkeit.',
      button: 'Jetzt Starten'
    },
    checkmark_section: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Die Achtsamkeits-App Balloon sorgt für Gesundheit, Klarheit und Gelassenheit. In den audiobasierten Kursübungen wird Wissen mit gewohnheitsbildender Praxis verbunden. Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder unterwegs',
      checkmark_3: 'Herausforderungen stressfrei begegnen'
    },
    ballooni_section: {
      headline: 'Für Mitglieder unseres Partners XING Premium ist das Kurs-Paket kostenlos.'
    }
  },
  xing_popup: {
    headline: 'Navigieren in der VUCA-Welt mit Psychologe und Neurowissenschaftler Dr. Boris Bornemann',
    subline: 'Wann und wo?',
    text_subline: 'Am 09.11.2023, Uhrzeit: 17:00 - 18:00 Uhr',
    subline_1: 'Für alle XING Premium Mitglieder vollständig kostenlos',
    text_subline_1:
      'Die Merkmale der modernen Welt werden oft mit der Abkürzung VUCA beschrieben (volatile, uncertain, complex, and ambiguous). In einer solchen Welt ist es schwer, sich allein mit dem rationalen (analytischen) Verstand zu orientieren. Seine Bearbeitungskapazität ist sehr begrenzt. Wir probieren es oft trotzdem. Das führt zum einen dazu, dass wir in einigen praktischen Dingen scheitern. Zum anderen kann es uns auch unglücklich machen. Wir überfordern uns, mit dem Versuch alles rational zu beherrschen und zu verstehen. Insbesondere für Fragen von Sinn und Bedeutung ist die Ratio allein auch einfach der falsche Ansprechpartner. Aber welche Alternativen gibt es zum analytischen Verstand?',
    text_subline_1a:
      'In dieser XING-Live-Session mache ich einige Vorschläge dazu. Ich spreche über andere Formen der Intelligenz (z.B. unsere Intuition und unsere Herzintelligenz). Ich stelle deren wissenschaftlichen Grundlagen vor. Wir machen einige praktische Übungen, um mit diesen alternativen Arten der Intelligenz in Kontakt zu kommen. Du lernst ein inneres Navigationssystem kennen, mit der du in der VUCA-Welt zielführender und zufriedener leben kannst.',
    text_subline_1b:
      'Mit mehreren kurzen Übungen hilft er Dir, innere Orientierung zu erlangen und besser mit schwierigen Gefühlen umzugehen, damit Du diese besondere Zeit bewusst und mit Freude gestalten kannst. Nimm Dir eine Stunde, um innezuhalten, zu reflektieren und neue Fähigkeiten zu erwerben - für Deinen beruflichen Weg und ein erfülltes Leben.',
    subline_2: 'Teilnahme',
    text_subline_2:
      'Alle XING Premium Mitglieder können am Live-Event teilnehmen und sich über den folgenden Button für das Event registrieren. Am Veranstaltungstag wird der (Zoom-)Link zum online Event auch per E-Mail versandt.'
  },
  xing_pro_jobs_popup: {
    headline: 'Navigieren in der VUCA-Welt mit Psychologe und Neurowissenschaftler Dr. Boris Bornemann',
    subline: 'Wann und wo?',
    text_subline: 'Am 09.11.2023, Uhrzeit: 17:00 - 18:00 Uhr',
    subline_1: 'Für alle XING ProJobs Mitglieder vollständig kostenlos',
    text_subline_1:
      'Die Merkmale der modernen Welt werden oft mit der Abkürzung VUCA beschrieben (volatile, uncertain, complex, and ambiguous). In einer solchen Welt ist es schwer, sich allein mit dem rationalen (analytischen) Verstand zu orientieren. Seine Bearbeitungskapazität ist sehr begrenzt. Wir probieren es oft trotzdem. Das führt zum einen dazu, dass wir in einigen praktischen Dingen scheitern. Zum anderen kann es uns auch unglücklich machen. Wir überfordern uns, mit dem Versuch alles rational zu beherrschen und zu verstehen. Insbesondere für Fragen von Sinn und Bedeutung ist die Ratio allein auch einfach der falsche Ansprechpartner. Aber welche Alternativen gibt es zum analytischen Verstand?',
    text_subline_1a:
      'In dieser XING-Live-Session mache ich einige Vorschläge dazu. Ich spreche über andere Formen der Intelligenz (z.B. unsere Intuition und unsere Herzintelligenz). Ich stelle deren wissenschaftlichen Grundlagen vor. Wir machen einige praktische Übungen, um mit diesen alternativen Arten der Intelligenz in Kontakt zu kommen. Du lernst ein inneres Navigationssystem kennen, mit der du in der VUCA-Welt zielführender und zufriedener leben kannst.',
    text_subline_1b:
      'Mit mehreren kurzen Übungen hilft er Dir, innere Orientierung zu erlangen und besser mit schwierigen Gefühlen umzugehen, damit Du diese besondere Zeit bewusst und mit Freude gestalten kannst. Nimm Dir eine Stunde, um innezuhalten, zu reflektieren und neue Fähigkeiten zu erwerben - für Deinen beruflichen Weg und ein erfülltes Leben.',
    subline_2: 'Teilnahme',
    text_subline_2:
      'Alle XING ProJobs Mitglieder können am Live-Event teilnehmen und sich über den folgenden Link für das Event registrieren. Am Veranstaltungstag wird der (Zoom-)Link zum online Event per E-Mail versandt.'
  },
  xing_pro_jobs: {
    header: {
      headline: 'Gesundheit und Resilienz für deinen persönlichen Erfolg.',
      subline:
        'Im exklusiven Kurspaket für XING ProJobs Mitglieder lernst du den richtigen Mix aus Achtsamkeit und Strategien zum Erreichen deiner Ziele.',
      button: 'Jetzt Starten'
    },
    checkmark_section: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Die Achtsamkeits-App Balloon sorgt für Gesundheit, Klarheit und Gelassenheit. In den audiobasierten Kursübungen wird Wissen mit gewohnheitsbildender Praxis verbunden. Dr. Boris Bornemann, promovierter Psychologe und Meditationsforscher, ist Kopf und Stimme der Balloon-App.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder unterwegs',
      checkmark_3: 'Alle 4 Kurse und 10 Einheiten aus dem XING Premium Kurspaket für den Joballtag',
      plus_2:
        '2 exklusive Live-Events mit Diplom-Psychologe und Neurologe Dr. Boris Bornemann zu den Themen „Achtsamkeit & Resilienz“ und „Im Übergang“'
    },
    ballooni_section: {
      headline: 'Für Mitglieder unseres Partners XING ProJobs ist das Kurs-Paket kostenlos.'
    }
  },
  gothaer: {
    header: {
      headline: 'Schön, dass du dich für Balloon entschieden hast!',
      subline:
        'Meditieren war noch nie so einfach - starte jetzt mit Balloon und lass dir die Kosten für den Jahreszugang von der Gothaer erstatten.',
      button: 'Jetzt Starten'
    },
    checkmark_section: {
      headline: 'Balloon macht dein Leben leichter.',
      subline_1:
        'Die Achtsamkeits App Balloon sorgt für Gesundheit, Klarheit und Gelassenheit. Die audiobasierten Kursübungen verbinden Wissen und gewohnheitsbildende Praxis zu Themen wie Stress, Gedanken, Gefühlen und Gelassenheit.',
      subline_2:
        'Kopf und Stimme der Balloon App ist Dr. Boris Bornemann. Der promovierte Psychologe und Meditationsforscher gehört zu Deutschlands führenden Achtsamkeits-Expert:innen. Er stellt sicher, dass der Kurs wissenschaftlich Hand und Fuß hat und hält, was er verspricht.',
      checkmark_1: 'Techniken für Anfänger und Fortgeschrittene',
      checkmark_2: '10 Minuten am Tag, im Homeoffice oder unterwegs',
      checkmark_3: 'Herausforderungen mit Resilienz begegnen'
    },
    ballooni_section: {
      headline: 'Mit dem Gothaer FlexSelect Premium Tarif bekommst du ganz einfach den Jahreszugang erstattet.',
      ballooni_2:
        'Du erhältst die Rechnung zu deinem Kauf per E-Mail. Diese reichst du anschließend bei der Gothaer Krankenversicherung ein.',
      ballooni_3: 'Die Kosten für deinen Kauf werden über dein zur Verfügung stehendes Gesundheitsbudget bei der Gothaer erstattet.'
    },
    buy_box_section: {
      headline: 'Dein Balloon-Jahreszugang',
      description: 'Erhalte jetzt Zugriff auf die komplette Balloon-Bibliothek mit Kursen zu Themen wie Stress, Schlaf und Konzentration.',
      relator: '*Kosten werden vollständig von der Gothaer erstattet'
    }
  },
  landingpages: {
    disclaimer: '*Der Zugang endet nach einem Jahr und verlängert sich nicht automatisch. Erfordert Android 8.0 bzw. iOS 13.0 oder höher.'
  },
  //////HomePage Rebrush
  videoSection: {
    heading: 'Lass uns noch heute loslegen!',
    body: 'Tauche ein in die Welt von Balloon: Du erfährst, wie Meditation dein Leben leichter macht und bekommst wirksame Tipps für deine Praxis.',
    link: 'So funktioniert‘s - Video starten',
    alt: 'Videoeinführung in die Meditation mit Balloon: Das ist die Meditations-App'
  },
  podcastSection: {
    heading: 'Der Balloon Podcast',
    body: 'Ein achtsamer Perspektivwechsel: Glück, Angst, Liebe und Vertrauen – in diesem Podcast gehen wir den ganz großen Dingen im Kleinen nach.',
    altMobileXS: 'Podcast-Folgen des Achtsamkeitspodcasts lassen sich direkt in der Balloon Mediations-App hören',
    altBubble: 'Podcast Illustration: Sprechblase',
    btn: 'Zum Podcast'
  },
  ctaInfoSection: {
    heading: 'Hol dir die Balloon App',
    body: 'Jetzt App installieren und sofort loslegen: In unserem gratis Einstiegskurs lernst du in nur 7 Tagen, wie Meditieren dir helfen kann, gelassener zu werden, weniger gestresst zu sein und besser zu schlafen. Probier es aus!',
    alt: 'Glücklich sein lernen mit der Meditations-App Balloon',
    btn: 'App jetzt herunterladen'
  },
  benefitInfoSection: {
    heading: 'Vielfältig meditieren mit Balloon',
    body: 'Stress reduzieren, Klarheit finden und endlich besser schlafen – mit deinem Balloon Abo erreichst du deine Ziele. Entdecke passende Einzelübungen und wirksame Kurse in unserer Meditations-Bibliothek.',
    btnDownload: 'App herunterladen',
    btnAbo: ' jetzt abonnieren'
  },
  borisSection: {
    heading: 'Kopf und Stimme hinter Balloon',
    body: 'Boris beschäftigt sich seit seinem Psychologie-Studium intensiv mit Meditation. Nach dem Studium hat er das ReSource-Projekt, die weltweit größte Studie zu Meditation, am Max-Planck-Institut mitkonzipiert. Er hat über Meditation promoviert und interessiert sich jetzt vor allem dafür, wie sie sich möglichst einfach und effektiv vermitteln lässt.',
    blockquote: '„Im Hier und Jetzt sein mit all deiner Lebendigkeit. Das ist Meditation.“',
    figcaption: 'Dr. Boris Bornemann, Diplom-Psychologe, Neurowissenschaftler',
    alt: 'Balloon präsentiert Achtsamkeitsexperte und Neurowissenschaftler Dr. Boris Bornemann'
  },
  advantagesSection: {
    heading: 'Achtsamkeit im App-Format',
    body: 'Endlich loslegen mit Meditieren – so funktioniert’s:'
  },
  healthPageSection: {
    heading: 'Deine Krankenkasse zahlt!',
    body: 'Achtsamkeit wirkt! Deshalb übernimmt deine Krankenkasse jetzt bis zu 100% der Kosten für den Kurs “Stressreduktion durch Achtsamkeit”. Meditieren - einreichen - Geld zurück!',
    subline: 'Wenn du bei einer unserer ',
    textLink: 'Kooperations-Krankenkassen',
    subline_1: ' versichert bist, kannst du den Kurs sogar direkt starten, ganz ohne Vorauszahlung.',
    alt: 'ZPP',
    btn: 'Jetzt loslegen'
  },
  advantageItem1: {
    heading: 'Achtsamkeit wirkt',
    body: 'Weniger Stress, mehr Gelassenheit, besserer Schlaf – lass dich auf deinem Weg begleiten und finde Ruhe und Entspannung.',
    alt: 'Achtsamkeit – Balloon findet Entspannung durch Meditation'
  },
  advantageItem2: {
    heading: 'Wissenschaftlich fundiert',
    body: 'Balloon basiert auf neuesten Erkenntnissen der Psychologie und Neurowissenschaft. Die Wirksamkeit des Präventionskurses wurde in einer groß angelegten Studie bestätigt.',
    alt: 'Wissenschaft – Balloon entdeckt, fundiertes Wissen, Forschung und Wissenschaft'
  },
  advantageItem3: {
    heading: 'für deinen Alltag',
    body: 'Betrachte Themen, die dich in deinem Leben wirklich bewegen – in wenigen Minuten auf den Punkt gebracht.',
    alt: 'Alltagstauglichkeit – Der Weg zum Ziel: Meditieren'
  },
  trustBandSection: {
    heading: 'Das sagen andere über Balloon:'
  },
  testimonialFlow: {
    body: 'In der App erscheinen regelmäßig neue Inhalte, sodass eine umfassende Achtsamkeits-Bibliothek auf dem Smartphone entsteht - für den Weg ins Büro, die Mittagspause oder ruhige Momente auf dem Sofa.'
  },
  testimonialBrigitte: {
    body: 'Entspannen per App. Alles was man machen muss: Ruhig hinsetzen, Kopfhörer aufsetzen und der freundlichen Stimme lauschen, die alle weiteren Anweisungen (auf deutsch) durchgibt.'
  },
  testimonialStern: {
    body: 'Man kann Zen-Buddhismus studieren, um meditieren zu lernen oder sich die App "Balloon" aufs iPhone laden. Anklicken und Augen schließen.'
  },
  mobileShowcase: {
    altScreenMeditation: 'Ruhe und Gelassenheit finden durch Meditationen mit Balloon',
    altScreenExercise: 'Gefühle erkunden und Gefühle verstehen im Meditationskurs',
    altScreenCourse: 'Stärken bewusst machen mit der Achtsamkeits-App Balloon'
  },
  withdrawCacelation: {
    title: 'Dein Balloon-Abo verlängern',
    subline_1: 'Dein aktuelles Abo endet am ',
    subline_2: ' Verlängere es jetzt, um deinen Zugriff auf alle Balloon-Inhalte nicht zu verlieren!',
    btn_text: 'Jetzt verlängern',
    subscriptionDisclaimer:
      '* Das Angebot ist ab sofort ab dem Verlängerungszeitpunkt gültig. Du kannst das Abo jederzeit zum Ende der Abo-Laufzeit kündigen. Ansonsten wird das Abo 24 Stunden vor Ablauf automatisch um den jeweiligen Abo-Zeitraum verlängert. Die Bezahlung erfolgt vorab bzw. zum Zeitpunkt der Verlängerung. Erfordert Android 8.0 bzw. iOS 13.0 oder höher.'
  },
  resiliencePage: {
    cta: 'Jetzt Loslegen',
    checkout: {
      headerSubTitlePraeventionskursResilienz:
        'Erhalte jetzt Zugriff auf den Kurs „Resilienz - Sich selbst stärken und für sich sorgen" sowie auf die komplette Balloon-Bibliothek mit Kursen zu Themen wie Schlaf, Fokus und Klarheit.',
      btnText: 'Krankenkasse ändern',
      headline_1: 'Wie schön!',
      headline_2: 'Mach jetzt den Erstattungs-Check:',
      info: 'gibt es außerdem unter ',
      info_2: 'die Möglichkeit, den Präventionskurs direkt zu \nstarten, ohne etwas vorlegen zu müssen.',
      member: 'Für Versicherte der ',
      notice: 'Hinweis:',
      refundText: ' der Kosten für dieses Angebot erstattet.',
      calcBox: {
        price: 'Preis (Vorleistung)',
        refund: 'Erstattung',
        yourShare: 'Dein Anteil'
      },
      subline_1: 'Kein Abo, keine Kündigung notwendig.',
      subline_2: 'Wie hoch ist deine Erstattung?',
      text: 'Sichere dir den Präventionskurs „Resilienz - Sich selbst stärken und für sich sorgen" und 12 Monate Zugang zur gesamten Balloon Bibliothek.',
      time: 'Achte darauf, den Kurs in 6 Monaten abzuschließen.'
    },
    checkoutBox: {
      approvalText_1_a: 'Ich akzeptiere die',
      approvalText_1_b: 'und bestätige hiermit, dass keine Kontraindikationen für die Absolvierung des Kurses vorliegen.',
      approvalText_2: 'Ich akzeptiere die',
      approvalText_3: 'und die Hinweise zum',
      approvalText_4: 'zu',
      asteriskText:
        '* Freischaltung endet automatisch nach einem Jahr und wird nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0 oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind berücksichtigt.',
      ctaBtn: 'Jetzt kaufen',
      infoText: 'inkl. 12 Monate Balloon',
      interval: 'Einmalig *'
    },
    expert: {
      headline_1: 'Unser Experte',
      headline_2: 'Dr. Boris Bornemann',
      text: 'Boris ist promovierter Neurowissenschaftler. Er ist Experte für Achtsamkeit und Meditation und Mitverfasser der weltweit größten wissenschaftlichen Studie zu diesem Thema.'
    },
    header: {
      headline: 'Resilienz –\nSich selbst stärken\nund für sich sorgen',
      subline: 'Ein App-gestützter Online-Kurs'
    },
    intro: {
      headline: 'Sorge für dich!',
      text_1:
        'Mit dem App-gestützten Online-Kurs „Resilienz – sich selbst stärken und für sich sorgen“ lernst du Wege kennen, um deine psychische Widerstandskraft zu stärken.',
      text_2:
        'In leicht zugänglichen und verständlichen Einheiten vermittelt dir unser Experte Dr. Boris Bornemann einen guten Einstieg in das Thema Resilienz und zeigt dir, wie du dich durch die grundlegenden Aspekte Gegenwärtigkeit, Stärken von Ressourcen, Freundlichkeit und Mitgefühl in schwierigen Zeiten selbst gut unterstützen kannst.',
      text_3:
        'Begleitet wird der Kurs von E-Mails und Impulsen, die dir helfen, dein Leben so zu gestalten, dass du leichter gelassen bleiben und besser mit den unvermeidlichen Schwierigkeiten umgehen kannst.'
    },
    instructions: {
      cardBody_1:
        'Registriere dich oder melde dich an und hole dir für 75,00 € deinen Zugang zum Kurs „Resilienz - Sich selbst stärken und für sich sorgen – ein App-gestützter Online-Kurs" (54 Kurs-Einheiten). Um das Gelernte zu vertiefen, erhältst du außerdem für 12 Monate Zugang zur gesamten Balloon-App.',
      cardBody_2:
        'Absolviere den Kurs „Resilienz - Sich selbst stärken und für sich sorgen – ein App-gestützter Online-Kurs" in der Balloon-App vollständig innerhalb von 6 Monaten, um dir die Kosten von deiner Krankenkasse erstatten zu lassen.',
      cardBody_3:
        'Wenn du den Kurs erfolgreich innerhalb von 6 Monaten abgeschlossen hast, bekommst du dein Teilnahme-Zertifikat per E-Mail zugeschickt. Unser Kundensupport ist bei Fragen oder Problemen gerne für dich da!',
      cardBody_4:
        'Reiche den Zahlungsbeleg mit dem Zertifikat  bei der Krankenkasse ein, um deine Erstattung zu erhalten. Beachte, dass  der Kurs innerhalb von 6 Monaten vollständig abgeschlossen werden muss und dass die Erstattungssumme je nach Krankenkasse unterschiedlich ausfallen kann.',
      cardBody_5_a:
        'Bewahre nach dem Kauf den Kaufbeleg auf. Diesen benötigst du später bei deiner Krankenkasse für die Erstattung. Einige Kassen beschränken die Anzahl der Erstattungen pro Person auf 1-2 Maßnahmen im Jahr. Nähere Infos dazu erhältst du bei deiner Krankenkasse.',
      cardBody_5_b:
        'Nach 12 Monaten läuft dein Zugang automatisch aus, ohne, dass du ihn aktiv kündigen musst. Du kannst bei Gefallen den Kauf des Kurses erneut abschließen und dir nochmals von deiner Krankenkasse erstatten lassen.',
      headline_1: 'So funktioniert es',
      headline_2: 'In 4 Schritten zur Erstattung',
      headlineBold_1: 'Registrierung / Anmeldung',
      headlineBold_2: 'Kurs absolvieren',
      headlineBold_3: 'Zertifikat erhalten',
      headlineBold_4: 'Kosten erstatten lassen',
      headlineBold_5: 'Wichtige Hinweise',
      headlineLight_1: 'Schritt eins',
      headlineLight_2: 'Schritt zwei',
      headlineLight_3: 'Schritt drei',
      headlineLight_4: 'Schritt vier'
    }
  },
  healthMarketingPage: {
    headerSection: {
      headline: 'Schluss mit Stress –\n deine Krankenkasse\n zahlt!',
      copy: 'Achtsamkeit wirkt. Das weiß auch deine Krankenkasse. \nDeshalb erstattet sie dir bis zu 100% der Kosten für den Balloon Präventionskurs Stressreduktion durch Achtsamkeit. \nUnd: 12 Monate Zugriff auf alle Balloon-Inhalte \ngibt es von uns gratis dazu.',
      refundCheckBtn: 'Zum Erstattungs-Check',
      refundBtn: 'Zur Geld-Zurück-Garantie',
      altText: 'Balloon-Figur meditiert mit Kopfhörern'
    },
    benefitSection: {
      headline: 'Dein Paket für mehr Gelassenheit',
      checkmark_1: 'Stress abbauen im wissenschaftlich fundierten Audiokurs',
      checkmark_2: '52 Einheiten mit über 600 Minuten Inhalt',
      checkmark_3: 'Begleitende E-Mails und Zusatzinformationen',
      checkmark_4: 'Dein Geschenk zum Dranbleiben: Gratis Jahreszugang zur gesamten Meditations-App.',
      playBtn: 'Jetzt reinhören',
      altText: 'Balloon-Figur findet Ruhe',
      altTextPlayer: 'Kurs Stressreduktion durch Schtsamkeit'
    },
    balloonieSection: {
      headline_1: 'Mit Leichtigkeit',
      headline_2: 'zu deiner Erstattung',
      balloonie_1: 'Kurs kaufen',
      balloonie_2: 'Einheiten hören &\n Stress reduzieren',
      balloonie_3: 'Kurs abschließen und mit der Balloon-App weiterhören',
      balloonie_4: 'Kosten erstatten\n lassen',
      ctaBtn: 'Zum Erstattungs-Check',
      altText_1: 'Balloon Figur zeigt "Daumen hoch',
      altText_2: 'Balloon-Figur entspannt auf einer Wiese',
      altText_3: 'Balloon-Figur mit Abschluss-Hut und Zertifikat',
      altText_4: 'Balloon-Figur mit erhobenen Händen'
    },
    refundSection: {
      headline: 'Balloon-Erstattungs-Garantie',
      copy_1:
        'Du verdienst einen entspannten Start in die Welt der Achtsamkeit. Auf unsere Erstattungs-Garantie kannst du dich verlassen. Das sind die Voraussetzungen:',
      checkmark_1: 'Bei Kauf des Kurses hast du noch mind. 75€ Präventionsbudget bei deiner Krankenkasse übrig.',
      checkmark_2: 'Du schließt den Kurs innerhalb von sechs Monaten nach dem Kauf ab.',
      copy_2:
        'Wenn du jetzt Teilnahmebestätigung und Rechnung bei deiner deutschen gesetzlichen Krankenkasse einreichst und die angegebene Erstattung abgelehnt wird, springen wir ein. Versprochen!',
      ctaBtn: 'jetzt loslegen',
      altText: 'Balloon-Figur mit Sonnenbrille'
    },
    borisSection: {
      headline_1: 'Unser Experte',
      headline_2: 'Dr. Boris Bornemann',
      copy: 'Boris hat den Kurs Stressreduktion durch Achtsamkeit konzipiert und eingesprochen. Seine Stimme führt dich auch durch die meisten anderen Meditationen und Inspirationen in der Balloon-Bibliothek.',
      borisInfoBtn: 'mehr zu Boris',
      altText: 'Balloon-Figur schickt Herz an Dr. Boris Bornemann'
    },
    ratingSection: {
      headline: 'Achtsamkeit wirkt:\n Alle sagen das',
      copy: 'Über 500.000 Menschen haben mit der Achtsamkeits-App Balloon die Kraft von Meditation entdeckt. Sie finden: Achtsamkeit wirkt! Das sieht man auch an Top-Bewertungen in App- und Playstore:',
      ratingCount: ' Bewertungen',
      altText: 'Balloon Figur hält Herz in der Hand'
    },
    faqSection: {
      headline: 'FAQ',

      headline_1: 'Wie schließe ich den Präventionskurs ab?',
      copy_1:
        'Der Präventionskurs gilt als abgeschlossen, wenn du alle Kurseinheiten der 8 Module gehört hast. Die Einheiten werden Stück für Stück freigeschaltet, damit du den Kurs in der richtigen Reihenfolge anhörst und keine Einheit verpasst. Nach jedem abgeschlossenen Modul bekommst du eine E-Mail mit weiterführenden Infos, einem Quiz und Literaturangaben. Ab Kauf des Kurses ist er 12 Monate für dich freigeschaltet. \nDamit die Balloon App auf deinem mobilen Endgerät läuft, benötigst du Android 8.0 bzw. iOS 13.0 oder höher.',

      headline_2: 'Bekomme ich auch Zugriff auf die gesamte Balloon-App?',
      copy_2: 'Ja, um im Kurs Gelerntes weiter zu vertiefen, geben wir dir für 12 Monate Zugriff auf die gesamte App gratis dazu.',

      headline_3: 'Ist der Präventionskurs ein Abo?',
      copy_3:
        'Nein, der Zugang zum Präventionskurs und der gesamten App läuft automatisch nach 12 Monaten aus. Eine Kündigung ist nicht erforderlich.',

      headline_4: 'Muss ich die 75€ immer vorlegen?',
      copy_4a: 'Normalerweise ja. Eine Ausnahme gilt bei unseren ',
      linkText_4: 'Krankenkassen-Kooperationen',
      copy_4b:
        '. Wenn du bei einer dieser Krankenkassen versichert bist, bekommst du einen Zugang zum Präventionskurs, ohne etwas vorlegen zu müssen.',
      headline_5: 'Was benötige ich für die Erstattung?',
      copy_5:
        'Wenn du den Präventionskurs abgeschlossen hast, kannst du die Erstattung bei deiner Krankenkasse beantragen. \n Dafür benötigst du den Zahlungsbeleg sowie die Teilnahmebestätigung des absolvierten Präventionskurses. Den Kaufbeleg bekommst du direkt nach der Kursbuchung per E-Mail zugesendet. Das Teilnahmezertifikat kommt ebenfalls per E-Mail. Es wird automatisch versendet, wenn du das letzte Modul des Präventionskurses abschließt. Zahlungsbeleg und Teilnahmebestätigung reichst du dann bei deiner Krankenkasse ein. Noch ein Hinweis: Krankenkassen können die Erstattung von Präventionsmaßnahmen pro Person auf 1-2 Maßnahmen im Jahr limitieren. Informiere dich hierzu bei deiner Krankenkasse.',
      subline_5: 'Details zur Zertifizierung des Präventionskurses:',
      body_5:
        'Der Präventionskurs ist nach §20 SGB V zertifiziert. \n Kurstitel: "Stressreduktion durch Achtsamkeit" \n Kursleiter: Dr. Boris Bornemann \n Kurs-ID: KU-ST-XXUBTU',

      headline_6: 'Für wen gilt die Möglichkeit der Erstattung?',
      copy_6a:
        'Damit du 75-100% der Kurskosten erstattet bekommst, musst du über 18 Jahre alt sein, in Deutschland leben und in Deutschland gesetzlich versichert sein. \n Sollte einer dieser Faktoren nicht auf dich zutreffen, können wir leider keine Erstattung garantieren. Natürlich kannst du den Präventionskurs trotzdem buchen. ',
      copy_6b: ' erfährst du mehr zu den Angeboten von Balloon.',
      urlBuy: 'https://www.balloonapp.de/buy'
    },
    courseSection: {
      headline: 'Dein Stresskurs in der\n Meditations-App Balloon',
      ctaBtn: 'Zum Erstattungs-Check'
    }
  },
  couponAdTile: {
    headline: 'Verschenke Leichtigkeit',
    subline: 'Entdecke die Balloon Gutscheinoptionen.',
    button: 'zu den gutscheinoptionen',
    altText: 'Balloonfigur trägt ein Geschenk'
  },
  giftCouponBanner: {
    headline: 'Balloon verschenken',
    subline:
      'Du kennst jemanden, dem du mehr Ruhe und Entspannung wünschst? Die Balloon App sorgt für weniger Stress, besseren Schlaf und mehr Gelassenheit im Alltag.',
    button: 'zu den gutscheinoptionen',
    altText: 'Balloonfigur trägt ein Geschenk'
  },
  subscriptionStatusDetail: {
    iTunesInfo:
      'Hinweis:\n Du hast dein Abo bei iTunes abgeschlossen.\n Ändern oder stornieren kannst du es nur in den Einstellungen deines iPhones:\nEinstellungen > Apple ID > Abos',
    googleInfo:
      'Hinweis:\n Du hast dein Abo beim Google Play Store abgeschlossen.\n Ändern oder stornieren kannst du es nur im Google Play Store:\n Öffne dafür die App Google Play Store und gehe zu > Menü > Meine Apps > Abonnements',
    stripeInfo:
      'Die Balloon Bibliothek ist aktuell für dich freigeschaltet. Wenn du deine Freischaltung verändern möchtest, musst du deine aktuelle Freischaltung ggf. kündigen und warten bis sie ausläuft.',
    headline: 'Freischaltung',
    upgradeTitle: 'Auf ein Jahres-Abo upgraden und 60€ pro Jahr sparen!',
    upgradeButton: 'UPGRADE',
    runtimeCurrentPeriode: 'Laufzeit aktuelle Periode',
    runtimeLastPeriode: 'Laufzeit letzte Periode',
    noEnded: 'endet nicht',
    subscriptionStateActive: 'Aktiv',
    subscriptionStateEnded: 'Beendet',
    linkAboRenew: 'ABO VERLÄNGERN',
    payWith: 'Bezahlt mit',
    payWithApple: 'Apple iTunes',
    payWithGoogle: 'Google Play Store',
    payWithStripeCard: 'Kreditkarte',
    payWithStripeDebit: 'Lastschriftverfahren',
    payWithStripePayPal: 'PayPal',
    payWithVoucer: 'Gutschein',
    currentContract: 'Dein aktueller Vertrag',
    cancelContract: 'Vertrag jetzt kündigen'
  },
  intervalText: {
    firstMonth: 'im ersten Monat',
    firstMonths: 'monatlich, für die\nersten',
    firstYear: 'im ersten Jahr',
    yearly: 'pro Jahr',
    quarterly: 'vierteljährlich',
    monthly: 'pro Monat',
    months: 'Monate',
    onetime: 'einmalig'
  },
  googleLogin: {
    info: 'Aufgrund deiner Sicherheitseinstellungen musst du dich neu Anmelden.'
  },
  meta: {
    pageTitle: {
      home: 'Meditation per App',
      contact: 'Kontakt',
      imprint: 'Impressum',
      login: 'Anmelden',
      plans: 'Abo-Auswahl',
      checkout: 'Jetzt kaufen | Preise',
      profile: 'Profil',
      profilePersonalData: 'Persönliche Daten | Profil',
      profileSubscriptionStatus: 'Deine Freischaltung | Profil',
      signup: 'Registrieren',
      thankyou: 'Abgeschlossen | Kaufen | Preise',
      thankyougift: 'Abgeschlossen | Kaufen | Preise',
      thankyouloggedout: 'Abgeschlossen | Kaufen | Preise',
      thankyoupurchaseassociated: 'Abgeschlossen | Kaufen | Preise',
      thankyouupgrade: 'Abgeschlossen | Kaufen | Preise',
      paymenterror: 'Fehler | Kaufen | Preise',
      licht: 'Tageslichtlampe TL 100 | BEURER',
      stress: 'StressreleaZer | BEURER',
      buyGift: 'Verschenken',
      library: 'Bibilothek',
      kaifu: 'KAIFU',
      stressreduktion: 'Stressreduktion mit deiner Krankenkasse',
      krankenkasse: 'Stressreduktion mit deiner Krankenkasse',
      voucher: 'Gutschein | Profil',
      podcast: 'Podcast',
      voucher_manual: 'Anleitung | Gutschein | Profil',
      company: 'Für Unternehmen',
      bkk: 'Bertelsmann BKK',
      mkk: 'Meine Krankenkasse mkk',
      hkk: 'hkk | Krankenkaasse',
      vividabkk: 'vivida bkk | Krankenkaasse',
      xing: 'XING Premium',
      xingProJobs: 'XING ProJobs',
      terminate: 'Abo Kündigung',
      terminateSuccess: 'Abo gekündigungen',
      gothaer: 'Gothaer',
      dak: 'DAK Antistress-Coaching',
      dakBgm: 'DAK-BGM Antistress-Coaching',
      bigDirect: 'BIG direkt gesund | Krankenkasse',
      ikkClassic: 'IKK Classic | Krankenkasse',
      salusBkk: 'Salus BKK | Balloon',
      viactiv: 'VIACTIV',
      tk: 'Techniker Krankenkasse',
      facebookPrivacy: 'Datenschutz | Facebook',
      aokBayern: 'AOK Bayern | Balloon'
    }
  },
  footer: {
    buyGift: 'Geschenk-Gutschein kaufen',
    company: 'Für Unternehmen',
    contact: 'Kontakt',
    copyright: '© Balloon ',
    getApp: 'HOL DIR DIE BALLOON APP',
    healthAdvisor: 'Für Gesundheitsberatende',
    trustPortal: 'Sicherheits-Portal',
    krankenkasse: 'Krankenkasse',
    library: 'Meditationen',
    yourBalloon: 'DEIN BALLOON',
    partnerships: 'PARTNERSCHAFTEN',
    plans: 'Preise',
    refundCheck: 'Erstattungs-Check',
    terminate: 'Verträge hier kündigen',
    voucher: 'Geschenk-Gutschein einlösen',
    yourAccess: 'DEIN ZUGANG',
    cooperations: 'Kooperationen mit Krankenkassen'
  },
  healthCooperations: {
    headline: 'Unsere Kooperationen'
  },
  mixedCooperations: {
    headline: 'Diese Unternehmenspartner vertrauen uns bereits'
  },
  trustPortal: {
    headerSection: {
      headline: 'Stressreduktion beginnt mit Sicherheit und Vertrauen',
      description: 'So setzen wir bei Balloon auf Sicherheit, Compliance und Datenschutz',
      buttonLabel: 'Frage stellen'
    },
    signetSection: {
      headline: 'Geprüfte Sicherheit und Wirksamkeit',
      signPreventionLabel: 'Zertifiziert und anerkannt von gesetzlichen Krankenkassen',
      signDsgvoLabel: 'Wir agieren im Sinne der DSGVO',
      mime: 'MissionMe',
      mimeUrl: 'https://www.missionme.de',
      signIsoLabel: ' ist zertifiziert nach ISO 27001:2022'
    },
    descriptionSection: {
      headline: 'Diese Themen stehen bei uns im Fokus',
      list1: {
        headline: 'Vertraulichkeit im Umgang mit sensiblen Daten',
        entry1: [
          'Schutz der Vertraulichkeit:',
          'Weitergabe von Informationen an Dritte wird vermieden und passiert ansonsten nur nach Konsent-Abfragen an unsere Nutzer (DSGVO).'
        ],
        entry2: [
          'Zugangskontrolle:',
          'Nutzer erhalten nur Zugriff auf die Ressourcen, zu denen sie berechtigt sind. Die Berechtigungen werden streng kontrolliert und regelmäßig überprüft.'
        ],
        entry3: [
          'Sicherung der Integrität:',
          'Vorkehrungen werden getroffen, um zu verhindern, dass Informationen unbefugt verändert werden.'
        ],
        entry4: [
          'Schutz der IT-Vermögenswerte:',
          'Alle Computer, mobilen Geräte, Netzwerkausrüstungen, Software und sensiblen Daten werden vor internen, externen, absichtlichen oder zufälligen Bedrohungen geschützt. Dies mindert die Risiken von Diebstahl, Verlust, Missbrauch und Schaden.'
        ]
      },
      list2: {
        headline: 'Erfüllung regulatorischer Vorgaben',
        entry1: [
          'Einhaltung gesetzlicher Anforderungen:',
          'Alle nationalen gesetzlichen und regulatorischen Anforderungen sowie Standards und Best Practices werden eingehalten und, wenn möglich, übertroffen.'
        ]
      },
      list3: {
        headline: 'Geschäftspraxis',
        entry1: [
          'Aufrechterhaltung der Verfügbarkeit:',
          'Es wird gewährleistet, dass autorisierte Personen bei Bedarf auf die Informationen zugreifen können, um die Geschäftsprozesse aufrecht zu erhalten.'
        ],
        entry2: [
          'Entwicklung und Pflege von Geschäftskontinuitätsplänen:',
          'Diese Pläne sollen sicherstellen, dass das Unternehmen trotz aller Hindernisse weiterhin arbeiten kann.'
        ]
      },
      list4: {
        headline: 'Umgang im Unternehmen',
        entry1: [
          'Sensibilisierung und Schulung der Mitarbeiter:',
          'Alle Mitarbeiter werden regelmäßig in Informationssicherheit geschult und sensibilisiert. Die Einhaltung der Sicherheitsanforderungen ist Teil der Unternehmenskultur.'
        ],
        entry2: [
          'Schutz der Mitarbeiter bei der Meldung von Sicherheitsbedenken:',
          'Mitarbeiter werden ermutigt, Sicherheitsbedenken zu melden, ohne negative Konsequenzen zu befürchten.'
        ],
        entry3: [
          'Kontinuierliche Verbesserung:',
          'Das Management von Balloon hat sich verpflichtet, eine Kultur der kontinuierlichen Verbesserung der Informationssicherheit zu fördern und die Anpassungsfähigkeit an sich entwickelnde Herausforderungen und technologische Fortschritte zu verbessern.'
        ]
      }
    },
    contactSection: {
      headline: 'Du hast Fragen?',
      description_1:
        'Wir sind da und bereit, deine Fragen zu beantworten. Solltest du Sicherheitsbedenken haben oder Detailfragen zu unserem Vorgehen, zögere nicht uns zu kontaktieren.',
      description_2:
        'Wenn du eine Sicherheitslücke entdeckst, bitten wir dich, so zu handeln, dass die Daten unserer Nutzer geschützt werden:',
      description_3:
        'Wenn du ein Sicherheitsproblem findest, kontaktiere uns bitte mit entsprechenden Details, einschließlich Schritten zur Reproduktion oder einem Proof-of-Concept.',
      list: [
        'Informiere uns so schnell wie möglich.',
        'Teste mit gefälschten Daten und Konten, nicht mit privaten Daten unserer Nutzer',
        'Arbeite mit uns zusammen, um die Schwachstelle zu schließen, bevor du sie an andere weitergibst.'
      ],
      contactFormHeadline: 'Kontakt',
      contactFormDescription1: 'Bei Fragen oder wenn du einen Sicherheitsvorfall melden willst, kontaktiere bitte',
      contactFormDescription2: 'Vielen Dank für deine Hilfe. Wir werden uns umgehend mit dir in Verbindung setzen.',
      buttonLabel: 'Sicherheitsvorfall melden'
    }
  }
};
